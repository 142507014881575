export const FETCH_SAFESCARF_PRODUCTS_STARTED = 'FETCH_SAFESCARF_PRODUCTS_STARTED';
export const FETCH_SAFESCARF_PRODUCTS_FULLFILLED = 'FETCH_SAFESCARF_PRODUCTS_FULLFILLED';
export const UPDATE_SAFESCARF_PRODUCT_STARTED = 'UPDATE_SAFESCARF_PRODUCT_STARTED';
export const UPDATE_SAFESCARF_PRODUCT_FULLFILLED = 'UPDATE_SAFESCARF_PRODUCT_FULLFILLED';
export const CREATE_SAFESCARF_PRODUCT_STARTED = 'CREATE_SAFESCARF_PRODUCT_STARTED';
export const CREATE_SAFESCARF_PRODUCT_FULLFILLED = 'CREATE_SAFESCARF_PRODUCT_FULLFILLED';
export const DELETE_SAFESCARF_PRODUCT_STARTED = 'DELETE_SAFESCARF_PRODUCT_STARTED';
export const DELETE_SAFESCARF_PRODUCT_FULLFILLED = 'DELETE_SAFESCARF_PRODUCT_FULLFILLED';

export const fetchProducts = (productId) => (
  { type: FETCH_SAFESCARF_PRODUCTS_STARTED, payload: productId }
);

export const fetchProductsFullfilled = (data) => (
  { type: FETCH_SAFESCARF_PRODUCTS_FULLFILLED, payload: data }
);

export const updateProduct = (data) => (
  { type: UPDATE_SAFESCARF_PRODUCT_STARTED, payload: data }
);

export const updateProductFullfilled = (data) => (
  { type: UPDATE_SAFESCARF_PRODUCT_FULLFILLED, payload: data }
);

export const createProduct = (data) => (
  { type: CREATE_SAFESCARF_PRODUCT_STARTED, payload: data }
);

export const createProductFullfilled = (data) => (
  { type: CREATE_SAFESCARF_PRODUCT_FULLFILLED, payload: data }
);

export const deleteProduct = (id) => (
  { type: DELETE_SAFESCARF_PRODUCT_STARTED, payload: id }
);

export const deleteProductFullfilled = (id) => (
  { type: DELETE_SAFESCARF_PRODUCT_FULLFILLED, payload: id }
);
