export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_USER_LOCALE = 'SET_USER_LOCALE';
export const FETCH_USER_DATA_STARTED = 'FETCH_USER_DATA_STARTED';
export const FETCH_USER_DATA_FULLFILLED = 'FETCH_USER_DATA_FULLFILLED';

export const setUserLocale = (locale) => (
  { type: SET_USER_LOCALE, locale }
);

export const fetchUserData = (params) => (
  { type: FETCH_USER_DATA_STARTED, payload: params }
);

export const fetchUserDataFullfilled = (payload) => (
  { type: FETCH_USER_DATA_FULLFILLED, payload }
);
