import { Grid } from '@mui/material';

import MarkdownFromFile from '../../../components/MarkdownFromFile/MarkdownFromFile';
import safecsarfSpecification from '../../../markdowns/Safescarf/specification.md';

const SafescarfSpecification = () => (
  <Grid container padding="2rem 10rem">
    <Grid item>
      <MarkdownFromFile file={safecsarfSpecification} />
    </Grid>
  </Grid>
);

export default SafescarfSpecification;
