import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Typography } from '@mui/material';
import { ScaleButton, ScaleLink } from '@telekom/scale-components-react';

const setCookies = (action) => {
  const oneYear = new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toUTCString();
  document.cookie = `BasicCookies=true; expires=${oneYear}`;
  if (action === 'accept') {
    document.cookie = `ThirdPartyCookies=true; expires=${oneYear}`;
  } else {
    document.cookie = `ThirdPartyCookies=false; expires=${oneYear}`;
  }
};

const CookiesCheck = ({ excludedPaths, setCookiesModalOpened }) => {
  const intl = useIntl();
  const { pathname } = useLocation();

  useEffect(() => {
    if (!excludedPaths.includes(pathname)) {
      if ((document.cookie.indexOf('BasicCookies=') === -1)) {
        setCookiesModalOpened(true);
      }
    }
  });

  return (
    <>
      <Typography component="span" gutterBottom>
        {intl.formatMessage({ id: 'CookiesCheck' })}
        <ScaleLink href="/cookies-settings">{intl.formatMessage({ id: 'CookiesSettings' })}</ScaleLink>
        {intl.formatMessage({ id: 'CookiesCheck1' })}
        <ScaleLink href="/data-privacy">{intl.formatMessage({ id: 'PrivacyPolicy' })}</ScaleLink>
        {intl.formatMessage({ id: 'CookiesCheck2' })}
        <ScaleLink
          onClick={() => {
            setCookies('decline');
            setCookiesModalOpened(false);
          }}
        >
          {intl.formatMessage({ id: 'Decline' })}
        </ScaleLink>
        {intl.formatMessage({ id: 'CookiesCheck3' })}
      </Typography>
      <ScaleButton
        slot="action"
        variant="primary"
        onClick={() => {
          setCookies('accept');
          setCookiesModalOpened(false);
        }}
      >
        Accept
      </ScaleButton>
    </>
  );
};

CookiesCheck.propTypes = {
  excludedPaths: PropTypes.arrayOf(PropTypes.string).isRequired,
  setCookiesModalOpened: PropTypes.func.isRequired,
};

export default CookiesCheck;
