import { Grid, Typography } from '@mui/material';
import {
  ScaleButton,
  ScaleIconActionAdd,
  ScalePagination,
  ScaleTable,
  ScaleTextField,
  ScaleSwitch,
  ScaleTag,
  ScaleTooltip,
  ScaleProgressBar,
} from '@telekom/scale-components-react';
import { PropTypes } from 'prop-types';
import { useState } from 'react';
import { useIntl } from 'react-intl';

const rowActions = (action) => {
  switch (action.value) {
    case 'switch':
      return <ScaleSwitch color="primary" size="small" {...action.props} />;
    case 'progressBar':
      return <ScaleProgressBar showStatus {...action.props} />;
    case 'tags':
      return action.tags ? (
        <div>
          {action.tags.map((tag) => (
            <ScaleTag
              type="strong"
              style={{ marginRight: '0.3em' }}
              size="small"
              key={tag}
            >
              {tag}
            </ScaleTag>
          ))}
        </div>
      ) : null;
    default: return action.value;
  }
};


const Table = ({
  header,
  fields,
  rows,
  createAction,
  exportData,
  pageSize,
}) => {
  const [pageStart, setPageStart] = useState(0);
  const [filter, setFilter] = useState('');
  const tableSize = rows.length > pageSize ? pageSize : rows.length;
  const searchedRows = rows.filter((row) => row.data.find((item) => {
    const strItem = String(item.value);
    return strItem.includes(filter);
  }));
  const pageRows = searchedRows.slice(pageStart, pageStart + tableSize);
  const handlePagination = (e) => {
    const startPos = e.detail.startElement;
    setPageStart(startPos);
  };
  const intl = useIntl();
  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        paddingBottom="1rem"
      >
        <Grid item>
          <Typography variant="h4" color="primary">{header}</Typography>
        </Grid>
        <Grid item>
          <Grid
            container
            justifyContent="flex-end"
            alignItems="center"
            columnSpacing={1}
          >
            <Grid item>
              <ScaleTextField
                label="Search"
                type="search"
                onScale-change={(e) => {
                  setFilter(e.detail.value);
                  setPageStart(0);
                }}
              />
            </Grid>
            {createAction && (
              <Grid item>
                <ScaleTooltip content={createAction.tooltip} disabled={!createAction.tooltip}>
                  <ScaleButton title="Create" onClick={createAction.onClick} disabled={createAction.disabled}>
                    <ScaleIconActionAdd />
                    Create
                  </ScaleButton>
                </ScaleTooltip>
              </Grid>
            )}
            {exportData && (
              <Grid item>
                <ScaleButton
                  style={{ marginLeft: '0.5rem' }}
                  href={URL.createObjectURL(exportData.file)}
                  download={exportData.fileName}
                >
                  {exportData.name}
                </ScaleButton>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <ScaleTable>
        <table>
          <thead>
            <tr style={{ textAlign: 'center' }}>
              {fields.map((field) => (
                <th
                  id={field.id}
                  key={field.id}
                  style={{
                    minWidth: '10rem',
                  }}
                >
                  {field.title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {pageRows.length > 0 ? (
              pageRows.map((row) => (
                <tr
                  style={{ cursor: row.onRowClick ? 'pointer' : 'not-allowed' }}
                  key={row.key}
                  onClick={row.onRowClick ? () => row.onRowClick(row) : null}
                >
                  {row.data.map((cell) => (cell.field !== 'actions'
                    ? (<td key={cell.field + cell.value} style={{ textAlign: 'left' }}>{cell.value}</td>
                    ) : (
                      <td key={cell.field + cell.value} style={{ textAlign: 'left' }}>{rowActions(cell)}</td>
                    )))}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={fields.length} style={{ textAlign: 'center', padding: '3rem' }}>
                  {intl.formatMessage({ id: 'EmptyTable' })}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </ScaleTable>

      <ScalePagination
        style={{
          display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '1rem',
        }}
        pageSize={tableSize}
        startElement={pageStart}
        totalElements={searchedRows.length}
        hideBorder
        onScale-pagination={handlePagination}
      />
    </>
  );
};
Table.propTypes = {
  header: PropTypes.string,
  fields: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  })).isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    data: PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.object,
    ])),
    onRowClick: PropTypes.func,
  })).isRequired,
  createAction: PropTypes.shape({
    tooltip: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
  }),
  exportData: PropTypes.shape({
    file: PropTypes.shape({}),
    fileName: PropTypes.string,
    name: PropTypes.string,
  }),
  pageSize: PropTypes.number,
};

Table.defaultProps = {
  header: '',
  createAction: null,
  exportData: null,
  pageSize: 10,
};
export default Table;
