export const FETCH_BUSINESS_UNITS_STARTED = 'FETCH_BUSINESS_UNITS_STARTED';
export const FETCH_BUSINESS_UNITS_FULLFILLED = 'FETCH_BUSINESS_UNITS_FULLFILLED';
export const CREATE_BUSINESS_UNIT_STARTED = 'CREATE_BUSINESS_UNIT_STARTED';
export const CREATE_BUSINESS_UNIT_FULLFILLED = 'CREATE_BUSINESS_UNIT_FULLFILLED';
export const UPDATE_BUSINESS_UNIT_STARTED = 'UPDATE_BUSINESS_UNIT_STARTED';
export const UPDATE_BUSINESS_UNIT_FULLFILLED = 'UPDATE_BUSINESS_UNIT_FULLFILLED';
export const DELETE_BUSINESS_UNIT_STARTED = 'DELETE_BUSINESS_UNIT_STARTED';
export const DELETE_BUSINESS_UNIT_FULLFILLED = 'DELETE_BUSINESS_UNIT_FULLFILLED';
export const CLEAR_DELETION_STATUS = 'CLEAR_DELETION_STATUS';
export const fetchBusinessUnits = (data) => (
  { type: FETCH_BUSINESS_UNITS_STARTED, payload: data }
);
export const fetchBusinessUnitsFullfilled = (data) => (
  { type: FETCH_BUSINESS_UNITS_FULLFILLED, payload: data }
);
export const createBusinessUnit = (businessUnit) => (
  { type: CREATE_BUSINESS_UNIT_STARTED, payload: businessUnit }
);
export const createBusinessUnitFullfilled = (data) => (
  { type: CREATE_BUSINESS_UNIT_FULLFILLED, payload: data }
);
export const updateBusinessUnit = (businessUnit) => (
  { type: UPDATE_BUSINESS_UNIT_STARTED, payload: businessUnit }
);
export const updateBusinessUnitFullfilled = (data) => (
  { type: UPDATE_BUSINESS_UNIT_FULLFILLED, payload: data }
);
export const deleteBusinessUnit = (businessUnit) => (
  { type: DELETE_BUSINESS_UNIT_STARTED, payload: businessUnit }
);
export const deleteBusinessUnitFullfilled = (data) => (
  { type: DELETE_BUSINESS_UNIT_FULLFILLED, payload: data }
);

export const clearDeletionStatus = () => ({ type: CLEAR_DELETION_STATUS });
