import {
  FETCH_ORGANIZATIONS_FULLFILLED,
  CREATE_ORGANIZATION_FULLFILLED,
  UPDATE_ORGANIZATION_FULLFILLED,
  ACTIVATE_MARKETPLACEITEM_FULLFILLED,
} from './actions';

const organizationsAdminReducer = (state = [], action) => {
  switch (action.type) {
    case CREATE_ORGANIZATION_FULLFILLED:
      return [...state, action.payload];
    case FETCH_ORGANIZATIONS_FULLFILLED:
      return action.payload;
    case UPDATE_ORGANIZATION_FULLFILLED:
    case ACTIVATE_MARKETPLACEITEM_FULLFILLED:
      return state.map((organization) => {
        if (organization._id === action.payload._id) {
          return action.payload;
        }
        return organization;
      });
    default:
      return state;
  }
};

export { organizationsAdminReducer };
