import http from './httpClient';

class MonitoringMs {
  static getDashboard() {
    return http.get(`${import.meta.env.VITE_MONITORING_V1_BASE}/dashboard`);
  }

  static getPanel(data) {
    return http.post(`${import.meta.env.VITE_MONITORING_V1_BASE}/panel`, data);
  }
}

export default MonitoringMs;
