import { useState } from 'react';
import { useIntl } from 'react-intl';
import {
  Formik,
  Form,
} from 'formik';
import {
  ScaleButton,
  ScaleModal,
} from '@telekom/scale-components-react';
import * as Yup from 'yup';
import validator from 'validator';
import { Grid } from '@mui/material';
import useApiCall from '../../../hooks/useApiCall';

import Auth from '../../../api/auth';
import CopyToClipboard from '../../../components/CopyToClipboard';
import { FormikPasswordField } from '../../../components/Formik/index';

const ApiKeyForm = () => {
  const createApiKey = useApiCall(Auth.createApiKey, null, 'apiKeyGenerationErr');
  const [apiKey, setApiKey] = useState();
  const intl = useIntl();

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .test(
        'Strong password',
        intl.formatMessage({ id: 'ERR_PASSWORD_INVALID' }),
        (value) => (value ? validator.isStrongPassword(value, { minLength: 10 }) : true),
      ).required('Required'),
  });

  async function handleSubmit({ password }) {
    const [data] = await createApiKey(password);
    if (data) {
      setApiKey(data.apiKey);
    }
  }

  return (
    <>
      <ScaleModal
        opened={apiKey}
        size="small"
        heading="Your API key is ready"
        onScale-before-close={(event) => {
          event.preventDefault();
        }}
      >
        <CopyToClipboard
          data={apiKey}
          onSubmit={() => { setApiKey(); }}
        />
      </ScaleModal>
      <Formik
        initialValues={{ password: '' }}
        validationSchema={validationSchema}
        onSubmit={async (data) => handleSubmit(data)}
      >
        {({
          dirty,
          isValid,
          isSubmitting,
        }) => (
          <Form>
            <Grid
              container
              direction="column"
              alignItems="flex-end"
              rowGap={2}
              width="20rem"
              paddingTop="3rem"
            >
              <FormikPasswordField
                name="password"
                label="Password"
              />
              <ScaleButton
                type="submit"
                size="small"
                disabled={!dirty || (!isValid || isSubmitting)}
              >
                Generate API-key
              </ScaleButton>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ApiKeyForm;
