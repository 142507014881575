export const ORDER_INSTANCE_STARTED = 'ORDER_INSTANCE_STARTED';
export const ORDER_INSTANCE_FULLFILLED = 'ORDER_INSTANCE_FULLFILLED';
export const FETCH_INSTANCE_STARTED = 'FETCH_INSTANCE_STARTED';
export const FETCH_INSTANCE_FULLFILLED = 'FETCH_INSTANCE_FULLFILLED';

export const orderInstance = (data) => (
  { type: ORDER_INSTANCE_STARTED, payload: data }
);

export const orderInstanceFullfilled = () => (
  { type: ORDER_INSTANCE_FULLFILLED }
);

export const fetchInstance = () => (
  { type: FETCH_INSTANCE_STARTED }
);

export const fetchInstancefullfilled = (data) => (
  { type: FETCH_INSTANCE_FULLFILLED, payload: data }
);
