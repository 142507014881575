export const FETCH_ORGANIZATIONS_STARTED = 'FETCH_ORGANIZATIONS_STARTED';
export const FETCH_ORGANIZATIONS_FULLFILLED = 'FETCH_ORGANIZATIONS_FULLFILLED';
export const CREATE_ORGANIZATION_STARTED = 'CREATE_ORGANIZATION_STARTED';
export const CREATE_ORGANIZATION_FULLFILLED = 'CREATE_ORGANIZATION_FULLFILLED';
export const UPDATE_ORGANIZATION_STARTED = 'UPDATE_ORGANIZATION_STARTED';
export const UPDATE_ORGANIZATION_FULLFILLED = 'UPDATE_ORGANIZATION_FULLFILLED';
export const ACTIVATE_MARKETPLACEITEM_STARTED = 'ACTIVATE_MARKETPLACEITEM_STARTED';
export const ACTIVATE_MARKETPLACEITEM_FULLFILLED = 'ACTIVATE_MARKETPLACEITEM_FULLFILLED';

export const fetchOrganizations = (data) => (
  { type: FETCH_ORGANIZATIONS_STARTED, payload: data }
);
export const fetchOrganizationsFullfilled = (data) => (
  { type: FETCH_ORGANIZATIONS_FULLFILLED, payload: data }
);
export const createOrganization = (organization) => (
  { type: CREATE_ORGANIZATION_STARTED, payload: organization }
);
export const createOrganizationFullfilled = (data) => (
  { type: CREATE_ORGANIZATION_FULLFILLED, payload: data }
);
export const updateOrganization = (organization) => (
  { type: UPDATE_ORGANIZATION_STARTED, payload: organization }
);
export const activateMarketplaceItem = (organization) => (
  { type: ACTIVATE_MARKETPLACEITEM_STARTED, payload: organization }
);
export const updateOrganizationFullfilled = (data) => (
  { type: UPDATE_ORGANIZATION_FULLFILLED, payload: data }
);
export const activateMarketplaceItemFullfilled = (data) => (
  { type: ACTIVATE_MARKETPLACEITEM_FULLFILLED, payload: data }
);
