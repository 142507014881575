import { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Breadcrumbs, Grid, Typography } from '@mui/material';
import { ScaleModal } from '@telekom/scale-components-react';
import rbacCheck from '../../../../rbac/rbacCheck';

import Products from './Products';

import DeleteForm from '../../../../components/DeleteForm';
import OverviewTable from '../../../../components/OverviewTable/OverviewTable';
import EditProjectForm from './EditProjectForm';
import useApiCall from '../../../../hooks/useApiCall';
import Auth from '../../../../api/auth';


const Project = () => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const userData = useSelector((state) => state.user);
  const products = useSelector((state) => state.admin.products)
    .filter((product) => product.projectId === projectId);

  const [projectDetails, setProjectDetails] = useState({});
  const [deleteModalOpened, setDeleteModalOpened] = useState(false);
  const [editModalOpened, setEditModalOpened] = useState(false);

  const authoredToDeleteProject = rbacCheck(userData.roles, '/projects:DELETE');
  const canEditProjects = rbacCheck(userData.roles, '/projects:PATCH');
  const canDeleteProject = authoredToDeleteProject && products.length === 0;

  const getProjectDetailsCall = useApiCall(Auth.fetchV2ProjectById);
  const updateProjectCall = useApiCall(Auth.updateV2Project);
  const deleteProjectCall = useApiCall(Auth.deleteV2Project);

  async function getProjectDetails() {
    const [data] = await getProjectDetailsCall(projectId);
    if (data) setProjectDetails(data);
  }

  const deleteProject = async () => {
    const [data] = await deleteProjectCall(projectId);
    if (data) navigate(-1);
  };

  const updateProject = async (formValues) => {
    const [data, error] = await updateProjectCall({
      ...formValues,
      technicalLeadId: formValues.technicalLeadId || null,
      _id: projectId,
    });
    if (error) setEditModalOpened(false);
    if (data) {
      getProjectDetails();
      setEditModalOpened(false);
    }
  };

  useEffect(() => {
    getProjectDetails();
  }, []);

  function getProjectInfo() {
    return [
      { title: 'Description', value: projectDetails.description },
      {
        title: 'Organization',
        component: (
          <Link to={`/organizations/${projectDetails.organizationId}`}>
            {projectDetails.organization?.enterpriseName}
          </Link>
        ),
      },
      {
        title: 'Business unit',
        component: (
          <Link to={`/business-units/${projectDetails.businessUnitId}`}>
            {projectDetails.businessUnit?.name}
          </Link>
        ),
      },
      {
        title: 'Technical lead',
        value: projectDetails.technicalLead?.email,
        component: (
          <Link to={`/users/${projectDetails.technicalLead?.uuid}`}>
            {projectDetails.technicalLead?.email}
          </Link>
        ),
      },
    ];
  }


  return (
    <>
      {editModalOpened && (
        <ScaleModal
          opened
          size="small"
          heading={projectDetails.name}
          onScale-close={() => setEditModalOpened(false)}
        >
          <EditProjectForm
            project={projectDetails}
            onSubmit={updateProject}
            onCancel={() => setEditModalOpened(false)}
          />
        </ScaleModal>
      )}
      {deleteModalOpened && (
        <ScaleModal
          opened
          size="small"
          heading="Delete Project"
          onScale-close={() => setDeleteModalOpened(false)}
        >
          <DeleteForm
            fieldName="project name"
            fieldValue={projectDetails.name}
            messages={['projectDeleteConfirmation']}
            deleteFunc={deleteProject}
            onClose={() => setDeleteModalOpened(false)}
          />
        </ScaleModal>
      )}
      <Grid
        container
        direction="column"
      >
        <Grid item>
          <Breadcrumbs separator=" > ">
            <Link to="/organizations">organizations</Link>
            <Link to={`/organizations/${projectDetails.organizationId}`}>{projectDetails.organization?.enterpriseName}</Link>
            <Link to={`/organizations/${projectDetails.organizationId}/projects`}>projects</Link>
            <Typography>{projectDetails.name}</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <Grid
            container
            direction="column"
            rowGap={8}
            padding="2rem 10rem"
          >
            <Grid item>
              <OverviewTable
                header={projectDetails.name}
                rows={getProjectInfo()}
                editAction={{
                  onClick: () => setEditModalOpened(true),
                  disabled: !canEditProjects,
                  tooltip: 'editProjectDisabled',
                }}
                deleteAction={{
                  onClick: () => setDeleteModalOpened(true),
                  disabled: !authoredToDeleteProject && !canDeleteProject,
                  tooltip: 'businessUnitDeletePermissionErr',
                }}
              />
            </Grid>
            <Grid item>
              <Products />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Project;
