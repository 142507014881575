import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ScaleModal } from '@telekom/scale-components-react';

import {
  createMachineAccount,
  fetchMachineAccounts,
  removeMachineAccountTokens,
} from '../../../../../redux/safescarf/machineAccounts/actions';
import { updateUser } from '../../../../../redux/safescarf/users/actions';

import rbacCheck from '../../../../../rbac/rbacCheck';
import Table from '../../../../../components/Table';
import CopyApiKey from './CopyApiKey';


const MachineAccounts = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user);
  const machineAccounts = useSelector((state) => state.safescarf.machineAccounts);

  useEffect(() => {
    if (!machineAccounts.length) dispatch(fetchMachineAccounts());
  }, []);


  const machineAccountToken = machineAccounts.length > 0
    ? machineAccounts[machineAccounts.length - 1]?.token : null;
  const machineAccountName = machineAccounts.length > 0
    ? machineAccounts[machineAccounts.length - 1]?.username : '';

  const mapMachineAccountsToRows = machineAccounts.map((ma) => {
    const canUpdateMachineAccount = rbacCheck(userData.roles, '/products/safescarf/users:PUT', { userEmail: userData.email, rowEmail: ma.email });
    return {
      key: ma.username,
      data: [
        {
          field: 'Username',
          value: ma.username,
        },
        {
          field: 'Created by',
          value: ma.email,
        },
        {
          field: 'Activation Status',
          value: ma.is_active ? 'Activated' : 'Deactivated',
        },
        {
          field: 'actions',
          value: 'switch',
          props: {
            disabled: !canUpdateMachineAccount,
            checked: ma.is_active,
            label: ma.is_active ? 'Deactivate' : 'Activate',
            'onScale-change': () => {
              dispatch(updateUser({
                id: ma.id,
                username: ma.username,
                is_active: !ma.is_active,
              }));
            },
          },
        },
      ],
    };
  });


  return (
    <>
      {machineAccountToken && (
        <ScaleModal
          opened
          size="small"
          heading="Machine Account was Created"
          onScale-before-close={(event) => event.preventDefault()}
        >
          <CopyApiKey
            machineAccountName={machineAccountName}
            machineAccountToken={machineAccountToken}
            onSubmit={() => dispatch(removeMachineAccountTokens())}
          />
        </ScaleModal>
      )}
      <Table
        header="Machine Accounts"
        fields={[
          {
            id: 'username',
            title: 'Username',
          },
          {
            id: 'createdBy',
            title: 'Created by',
          },
          {
            id: 'activationStatus',
            title: 'Activation Status',
          },
          {
            id: 'actions',
            title: 'Actions',
          },
        ]}
        rows={mapMachineAccountsToRows}
        createAction={{
          onClick: () => dispatch(createMachineAccount()),
        }}
      />
    </>
  );
};


export default MachineAccounts;
