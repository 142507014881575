export const REQUEST_STARTED = 'REQUEST_STARTED';
export const REQUEST_FULLFILLED = 'REQUEST_FULLFILLED';
export const REQUEST_FAILED = 'REQUEST_FAILED';
export const RESET_DELETION_STATUS = 'RESET_DELETION_STATUS';

export const requestStarted = () => (
  { type: REQUEST_STARTED }
);
export const requestFullfilled = () => (
  { type: REQUEST_FULLFILLED }
);
export const requestFailed = () => (
  { type: REQUEST_FAILED }
);

export const resetDeletionStatus = () => (
  { type: RESET_DELETION_STATUS }
);
