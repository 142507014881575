import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BehaviorSubject } from 'rxjs';
import { mergeMap, debounceTime, filter } from 'rxjs/operators';
import * as Yup from 'yup';
import {
  Formik,
  Form,
  useFormikContext,
  useField,
} from 'formik';
import {
  ScaleButton,
  ScaleTextField,
  ScaleDropdownSelect,
  ScaleDropdownSelectItem,
  ScaleCheckbox,
  ScaleTag,
  ScaleLoadingSpinner,
} from '@telekom/scale-components-react';
import Typography from '@mui/material/Typography';
import { regexValidator } from '../../../../helpers/regexValidator';
import Provision from '../../../../api/provision';
import useApiCall from '../../../../hooks/useApiCall';

import { createProduct } from '../../../../redux/admin/products/actions';
import Auth from '../../../../api/auth';

function productTypesLookup(organization) {
  return organization.marketplaceItems?.reduce((acc, curr) => {
    if (['Safescarf', 'Evaluation', 'Demands'].includes(curr.name)) return acc;
    acc[curr.name] = curr.name;
    return acc;
  }, {}) || {};
}

const NameField = () => {
  const { setFieldTouched, setFieldValue, initialValues } = useFormikContext();
  const [field, meta] = useField({ name: 'name' });
  return (
    <div className="form-field">
      <ScaleTextField
        {...field}
        label="Product Name"
        helperText={meta.touched ? meta.error : null}
        onScale-change={(e) => {
          field.onChange(e);
          setFieldTouched('name');
          setFieldValue('juiceGroup', `${initialValues.juiceGroup}${e.target.value}`);
          setFieldTouched('juiceGroup');
        }}
      />
    </div>
  );
};

const juiceGroupSubject = new BehaviorSubject('');
const juiceGroupObservable = juiceGroupSubject.pipe(
  debounceTime(3000),
  filter((juiceGroup) => juiceGroup !== ''),
  mergeMap(Provision.fetchJuiceGroupByName),
);

const JuiceGroupField = () => {
  const {
    setFieldTouched, setStatus, setFieldError, status,
  } = useFormikContext();
  const [field, meta] = useField({ name: 'juiceGroup' });

  useEffect(() => {
    const subscription = juiceGroupObservable.subscribe((data) => {
      const juiceGroupAvailability = data.data?.title;
      if (juiceGroupAvailability !== 'Not Found') {
        setFieldError('juiceGroup', 'Juice Group is unavailable');
      }
      setStatus();
    });
    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    if (!meta.error) {
      setStatus('checking juice group');
      juiceGroupSubject.next(field.value);
    }
  }, [field.value, meta.error]);


  return (
    <div style={{
      display: 'inline-flex',
      width: status ? '104%' : '100%',
    }}
    >
      <div className="form-field">
        <ScaleTextField
          {...field}
          label="Juice Group"
          helperText={meta.touched ? meta.error : null}
          onScale-change={(e) => {
            field.onChange(e);
            setFieldTouched('juiceGroup');
          }}
        />
      </div>
      <div style={{ display: 'flex', marginLeft: '0.2rem' }}>
        {status && <ScaleLoadingSpinner />}
      </div>
    </div>
  );
};

const DescriptionField = () => {
  const { setFieldTouched } = useFormikContext();
  const [field, meta] = useField({ name: 'description' });
  return (
    <div className="form-field">
      <ScaleTextField
        {...field}
        label="Description"
        helperText={meta.touched ? meta.error : null}
        onScale-change={(e) => {
          field.onChange(e);
          setFieldTouched('description');
        }}
      />
    </div>
  );
};

const ProductTypeField = ({ productTypes }) => {
  const { setFieldTouched } = useFormikContext();
  const [field] = useField({ name: 'type' });

  return (
    <div className="form-field">
      <ScaleDropdownSelect
        {...field}
        label="Product Type"
        onScale-change={(e) => {
          field.onChange(e);
          setFieldTouched('type');
        }}
      >
        {Object.keys(productTypes).map(
          (key) => (
            <ScaleDropdownSelectItem key={key} value={key}>
              {productTypes[key]}
            </ScaleDropdownSelectItem>
          ),
        )}
      </ScaleDropdownSelect>
    </div>
  );
};

ProductTypeField.propTypes = { productTypes: PropTypes.objectOf(PropTypes.string).isRequired };

const Tags = ({ tags, setTags }) => {
  const fetchTags = useApiCall(Provision.fetchTags, null, null);
  const [juiceTags, setJuiceTags] = useState([]);

  useEffect(() => {
    async function getTags() {
      const [data] = await fetchTags();
      if (data) setJuiceTags(data);
    }
    getTags();
  }, []);

  return (
    <>
      <Typography align="left">
        Juice Group Tags:
      </Typography>
      <div style={{ display: 'grid', gridTemplateColumns: '50% 50%' }}>
        {juiceTags.map((tag) => (
          <ScaleCheckbox
            key={tag}
            style={{ marginTop: '0.5rem' }}
            checked={tags.includes(tag)}
            onScale-change={(event) => {
              if (event.target.checked) {
                setTags([...tags, tag]);
              } else {
                setTags(tags.filter((t) => t !== tag));
              }
            }}
          >
            <ScaleTag type="strong">{tag}</ScaleTag>
          </ScaleCheckbox>
        ))}
      </div>
    </>
  );
};

Tags.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
  setTags: PropTypes.func.isRequired,
};

const CreateProductForm = ({ formRef, onSubmit, onCancel }) => {
  const { projectId } = useParams();
  const dispatch = useDispatch();
  const [tags, setTags] = useState([]);
  const [initialValues, setInitialValues] = useState();
  const [project, setProject] = useState({});
  const [organization, setOrganization] = useState({});

  const getOrganizationCall = useApiCall(Auth.fetchV1OrganizationById);
  const getProjectCall = useApiCall(Auth.fetchV2ProjectById);

  const getProject = async () => {
    const [data] = await getProjectCall(projectId);
    if (data) {
      setProject(data);
      setInitialValues(
        {
          name: '',
          juiceGroup: `${data.organization.enterpriseName}-${data.name}-`,
          description: '',
          type: undefined,
        },
      );
    }
  };

  const getOrganization = async (id) => {
    const [data] = await getOrganizationCall(id);
    if (data) setOrganization(data);
  };

  useEffect(() => {
    getProject();
  }, []);

  useEffect(() => {
    if (project.organizationId) {
      getOrganization(project.organizationId);
    }
  }, [project]);


  const createProductCall = (data) => (dispatch(createProduct({
    ...data,
    tags,
    projectId,
    organizationId: project.organizationId,
  })));


  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Required').matches(regexValidator.name, 'All lower-case, no spaces, no special characters (dash only)'),
    juiceGroup: Yup.string()
      .required('Required')
      .matches(regexValidator.juiceGroup, 'The Juice Group must start with a letter and contain only lower-case letters, numbers, and dashes')
      .min(3, 'The Juice Group must be at least 3 characters long')
      .max(64, 'The Juice Group must be at most 64 characters long'),
    description: Yup.string().required('Required'),
    type: Yup.string().required('Required'),
  });

  const submit = (formValues) => {
    createProductCall(formValues);
    formRef.current.resetForm();
    onSubmit();
  };

  return initialValues && (
    <Formik
      innerRef={formRef}
      validateOnChange
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={submit}
    >
      {({ dirty, isValid, status }) => (
        <Form>
          <NameField />
          <JuiceGroupField />
          <DescriptionField />
          <ProductTypeField productTypes={productTypesLookup(organization)} />
          <Tags tags={tags} setTags={setTags} />
          <div className="form-buttons">
            <ScaleButton
              variant="primary"
              disabled={status || !dirty || !isValid}
            >
              Create
            </ScaleButton>
            <ScaleButton
              name="cancel"
              type="button"
              variant="secondary"
              style={{ marginLeft: '1em' }}
              onClick={() => {
                setTags([]);
                formRef.current.resetForm();
                onCancel();
              }}
            >
              Cancel
            </ScaleButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};

CreateProductForm.propTypes = {
  formRef: PropTypes.shape({
    current: PropTypes.shape({ resetForm: PropTypes.func.isRequired }),
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};


export default CreateProductForm;
