import { Container, Typography } from '@mui/material';
import { ScaleDivider } from '@telekom/scale-components-react';
import CookieSettings from './CookieSettings';


const Cookies = () => (
  <Container maxWidth="md">
    <Typography gutterBottom variant="h4" color="primary">Managing your cookies settings</Typography>
    <Typography gutterBottom>
      We use cookies to provide you with optimized website experience.
      These are small text files that are stored on your computer.
      They include cookies for the operation
      and optimization of the website as well as cookies for services,
      such as communication with our support.
      We want to give you the choice of which cookies are permitted.
    </Typography>
    <ScaleDivider />
    <CookieSettings />
  </Container>
);

export default Cookies;
