import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import {
  Formik,
  Form,
} from 'formik';
import { ScaleButton } from '@telekom/scale-components-react';
import { Grid } from '@mui/material';
import { FormikSelect, FormikTextField } from '../../../components/Formik/index';
import useApiCall from '../../../hooks/useApiCall';
import Auth from '../../../api/auth';

const BusinessUnitManagersField = ({ organizationId }) => {
  const [businessUnitManagers, setBusinessUnitManagers] = useState([]);
  const fetchBusinessUnitManagers = useApiCall(Auth.fetchV2Users);

  useEffect(() => {
    async function getBuManagers() {
      const [data] = await fetchBusinessUnitManagers({ organizationId, roles: 'BUSINESS_UNIT_MANAGER' });
      if (data) setBusinessUnitManagers(data);
    }
    if (organizationId) getBuManagers();
  }, [organizationId]);

  const businessUnitManagersItems = businessUnitManagers.map((manager) => ({
    value: manager._id,
    label: manager.email,
  }));

  return (
    <FormikSelect
      name="businessUnitManager"
      label="Business Unit Manager"
      menuItems={businessUnitManagersItems}
    />
  );
};

BusinessUnitManagersField.propTypes = {
  organizationId: PropTypes.string.isRequired,
};

const CreateBusinessUnitForm = ({ onSubmit, onCancel }) => {
  const { organizationId } = useParams();

  const initialValues = {
    name: '',
    description: '',
    businessUnitManager: '',
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    description: Yup.string().required('Required'),
  });

  return (
    <Formik
      validateOnChange
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ dirty, isValid }) => (
        <Form>
          <Grid
            container
            justifyContent="end"
            gap={2}
            py={2}
          >
            <FormikTextField
              name="name"
              label="Name"
            />
            <FormikTextField
              name="description"
              label="Description"
            />
            <BusinessUnitManagersField organizationId={organizationId} />
            <ScaleButton
              variant="primary"
              disabled={!dirty || !isValid}
            >
              Create
            </ScaleButton>
            <ScaleButton
              type="button"
              variant="secondary"
              onClick={onCancel}
            >
              Cancel
            </ScaleButton>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

CreateBusinessUnitForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default CreateBusinessUnitForm;
