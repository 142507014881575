import {
  CREATE_ORGANIZATION_FULLFILLED,
  UPDATE_ORGANIZATION_FULLFILLED,
} from '../organizations/actions';

import {
  FETCH_USERS_FULLFILLED,
  CREATE_USER_FULLFILLED,
  UPDATE_USER_FULLFILLED,
  DELETE_USER_FULLFILLED,
  ASSIGN_PRODUCT_FULLFILLED,
  UNASSIGN_PRODUCT_FULLFILLED,
} from './actions';

const usersAdminReducer = (state = [], action) => {
  switch (action.type) {
    case CREATE_USER_FULLFILLED:
      return [...state, action.payload];
    case FETCH_USERS_FULLFILLED:
      return action.payload;
    case UPDATE_USER_FULLFILLED:
      return [
        ...state.map((user) => {
          if (user.uuid === action.payload.uuid) {
            return action.payload;
          }
          return user;
        }),
      ];
    case ASSIGN_PRODUCT_FULLFILLED:
      return [
        ...state.map((user) => {
          if (user._id === action.payload._id) {
            return action.payload;
          }
          return user;
        }),
      ];
    case UNASSIGN_PRODUCT_FULLFILLED:
      return [
        ...state.map((user) => {
          if (user._id === action.payload._id) {
            return action.payload;
          }
          return user;
        }),
      ];
    case DELETE_USER_FULLFILLED:
      return [...state.filter((user) => user.uuid !== action.payload.uuid)];
    case CREATE_ORGANIZATION_FULLFILLED:
    case UPDATE_ORGANIZATION_FULLFILLED:
      return [
        ...state.map((user) => {
          if (action.payload.accountOwnerId === user._id) {
            return {
              ...user,
              organizationId: action.payload._id,
            };
          }
          return user;
        }),
      ];
    default:
      return state;
  }
};

export { usersAdminReducer };
