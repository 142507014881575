import { ofType } from 'redux-observable';
import { mergeMap, catchError } from 'rxjs/operators';
import { from, of } from 'rxjs';
import Auth from '../../../api/auth';
import {
  FETCH_MARKETPLACE_ITEMS_STARTED,
  fetchMarketplaceItemsFullfilled,
} from './actions';
import { enqueueSnackbar } from '../../notifier/actions';
import { requestFailed } from '../../status/actions';

const fetchMarketplaceItemsEpic = (action$) => action$.pipe(
  ofType(FETCH_MARKETPLACE_ITEMS_STARTED),
  mergeMap(() => from(Auth.fetchV1MarketplaceItems()).pipe(
    mergeMap((response) => of(
      fetchMarketplaceItemsFullfilled(response.data),
    )),
    catchError((error) => of(
      requestFailed(),
      enqueueSnackbar({
        message: error.message,
        options: { variant: 'error' },
      }),
    )),
  )),
);


export default fetchMarketplaceItemsEpic;
