import { ofType, combineEpics } from 'redux-observable';
import {
  mergeMap, catchError, tap, ignoreElements,
} from 'rxjs/operators';
import { from, of } from 'rxjs';

import Auth from '../../../api/auth';
import {
  FETCH_USERS_STARTED,
  fetchUsersFullfilled,
  CREATE_USER_STARTED,
  createUserFullfilled,
  UPDATE_USER_STARTED,
  updateUserFullfilled,
  DELETE_USER_STARTED,
  deleteUserFullfilled,
  ASSIGN_PRODUCT_STARTED,
  assignProductFullfilled,
  UNASSIGN_PRODUCT_STARTED,
  unassignProductFullfilled,
  UPDATE_USER_FULLFILLED,
  DELETE_USER_FULLFILLED,
} from './actions';
import { enqueueSnackbar } from '../../notifier/actions';
import { requestFailed } from '../../status/actions';

const fetchUsersEpic = (action$) => action$.pipe(
  ofType(FETCH_USERS_STARTED),
  mergeMap((action) => from(Auth.fetchV2Users(action.payload)).pipe(
    mergeMap((response) => of(
      fetchUsersFullfilled(response.data),
    )),
    catchError((error) => of(
      requestFailed(),
      enqueueSnackbar({
        message: error.message,
        options: { variant: 'error' },
      }),
    )),
  )),
);

const createUserEpic = (action$) => action$.pipe(
  ofType(CREATE_USER_STARTED),
  mergeMap((action) => from(Auth.createV2User(action.payload)).pipe(
    mergeMap((response) => of(
      createUserFullfilled(response.data),
      enqueueSnackbar({
        message: 'userCreation',
        values: { email: response.data.email },
        options: { variant: 'success' },
      }),
    )),
    catchError((error) => of(
      requestFailed(),
      enqueueSnackbar({
        message: error.message,
        options: { variant: 'error' },
      }),
    )),
  )),
);

const updateUserEpic = (action$) => action$.pipe(
  ofType(UPDATE_USER_STARTED),
  mergeMap((action) => from(Auth.updateV2User(action.payload)).pipe(
    mergeMap((response) => of(
      updateUserFullfilled({ ...response.data, successCallback: action.payload.successCallback }),
      enqueueSnackbar({
        message: 'userUpdated',
        options: { variant: 'success' },
      }),
    )),
    catchError((error) => of(
      requestFailed(),
      enqueueSnackbar({
        message: error.message,
        options: { variant: 'error' },
      }),
    )),
  )),
);

const deleteUserEpic = (action$) => action$.pipe(
  ofType(DELETE_USER_STARTED),
  mergeMap((action) => from(Auth.deleteV2User(action.payload.uuid)).pipe(
    mergeMap((response) => of(
      deleteUserFullfilled({ ...response.data, successCallback: action.payload.successCallback }),
      enqueueSnackbar({
        message: 'userDeleted',
        options: { variant: 'success' },
      }),
    )),
    catchError((error) => of(
      requestFailed(),
      enqueueSnackbar({
        message: error.message,
        options: { variant: 'error' },
      }),
    )),
  )),
);

const assignProductEpic = (action$) => action$.pipe(
  ofType(ASSIGN_PRODUCT_STARTED),
  mergeMap((action) => from(Auth.assignV2Product(action.payload)).pipe(
    mergeMap((response) => of(
      assignProductFullfilled(response.data),
      enqueueSnackbar({
        message: 'userAssigned',
        options: { variant: 'success' },
      }),
    )),
    catchError((error) => of(
      requestFailed(),
      enqueueSnackbar({
        message: error.message,
        options: { variant: 'error' },
      }),
    )),
  )),
);

const unassignProductEpic = (action$) => action$.pipe(
  ofType(UNASSIGN_PRODUCT_STARTED),
  mergeMap((action) => from(Auth.unassignV2Product(action.payload)).pipe(
    mergeMap((response) => of(
      unassignProductFullfilled(response.data),
      enqueueSnackbar({
        message: 'userUnassigned',
        options: { variant: 'success' },
      }),
    )),
    catchError((error) => of(
      requestFailed(),
      enqueueSnackbar({
        message: error.message,
        options: { variant: 'error' },
      }),
    )),
  )),
);

const fullfilledUserEpic = (actions$) => actions$.pipe(
  ofType(UPDATE_USER_FULLFILLED, DELETE_USER_FULLFILLED),
  tap((action) => { if (action.payload.successCallback) action.payload.successCallback(); }),
  ignoreElements(),
);

export default combineEpics(
  fetchUsersEpic,
  createUserEpic,
  updateUserEpic,
  deleteUserEpic,
  assignProductEpic,
  unassignProductEpic,
  fullfilledUserEpic,
);
