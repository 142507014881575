import { combineReducers } from 'redux';

import adminReducers from './admin/reducers';
import safescarfReducers from './safescarf/reducers';
import { statusReducer } from './status/reducer';
import { notifierReducer } from './notifier/reducer';
import { userReducer } from './user/reducer';
import { virtualCloudsReducer } from './virtualClouds/reducer';

const reducer = combineReducers({
  user: userReducer,
  notifier: notifierReducer,
  admin: adminReducers,
  safescarf: safescarfReducers,
  virtualClouds: virtualCloudsReducer,
  status: statusReducer,
});

export default reducer;
