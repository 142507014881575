import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import { ScaleButton } from '@telekom/scale-components-react';

const Page404 = () => {
  const navigate = useNavigate();

  return (
    <div style={{ padding: '10rem 0' }}>
      <Typography
        variant="h2"
        color="primary"
        gutterBottom
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        Thank you for your interest!
      </Typography>
      <Typography variant="h6" style={{ display: 'flex', justifyContent: 'center' }}>
        Unfortunately this page is not available.
      </Typography>
      <Typography variant="h6" paragraph style={{ display: 'flex', justifyContent: 'center' }}>
        Please check the address and try again.
      </Typography>
      <ScaleButton
        style={{ display: 'flex', justifyContent: 'center' }}
        variant="primary"
        onClick={() => navigate('/')}
      >
        Back to homepage
      </ScaleButton>
    </div>
  );
};

export default Page404;
