import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import {
  Formik,
  Form,
  useFormikContext,
  useField,
} from 'formik';
import {
  ScaleButton,
  ScaleDropdownSelect,
  ScaleDropdownSelectItem,
} from '@telekom/scale-components-react';

import {
  createProductMember,
  updateProductMember,
  deleteProductMember,
} from '../../../../../redux/safescarf/productMembers/actions';
import { fetchRoles } from '../../../../../redux/safescarf/roles/actions';

const roleLookup = (roles) => roles?.reduce((acc, role) => {
  acc[role.id] = role.name;
  return acc;
}, {}) || {};


const RoleField = ({ userTypeRoles }) => {
  const { setFieldTouched } = useFormikContext();
  const [field] = useField({ name: 'role' });

  return (
    <div className="form-field">
      <ScaleDropdownSelect
        {...field}
        floatingStrategy="fixed"
        label="Product Member Role"
        onScale-change={(e) => {
          field.onChange(e);
          setFieldTouched('role');
        }}
      >
        <ScaleDropdownSelectItem key="none" value="">None</ScaleDropdownSelectItem>
        {Object.keys(userTypeRoles).map((key) => (
          <ScaleDropdownSelectItem key={key} value={key}>
            {userTypeRoles[key]}
          </ScaleDropdownSelectItem>
        ))}
      </ScaleDropdownSelect>
    </div>
  );
};

RoleField.propTypes = {
  userTypeRoles: PropTypes.objectOf(PropTypes.string).isRequired,
};

const EditProductMemberForm = ({ productMember, onSubmit, onCancel }) => {
  const dispatch = useDispatch();
  const { productId } = useParams();
  const roles = useSelector((state) => state.safescarf.roles);

  useEffect(() => {
    if (!roles.length) dispatch(fetchRoles());
  }, []);

  const userTypeRoles = productMember?.isMachineAccount
    ? roleLookup(roles.filter((role) => role.id === 1))
    : roleLookup(roles.filter((role) => [2, 3, 5].includes(role.id)));

  const updateProductMemeberRole = async (role) => {
    if (productMember.productMemberId) {
      if (role !== '') {
        dispatch(updateProductMember({
          id: productMember.productMemberId,
          product: productId,
          user: productMember.userId,
          role,
        }));
      } else {
        dispatch(deleteProductMember(productMember.productMemberId));
      }
    } else if (role !== '') {
      dispatch(createProductMember({
        product: productId,
        user: productMember.userId,
        role,
      }));
    }
  };

  const submit = async (formValues) => {
    await updateProductMemeberRole(formValues.role);
    await onSubmit();
  };

  const initialValues = {
    role: productMember?.productMemberRole?.toString() || '',
  };

  return (
    <Formik
      enableReinitialize
      validateOnChange
      initialValues={initialValues}
      onSubmit={submit}
    >
      {({ dirty, isValid }) => (
        <Form>
          <RoleField userTypeRoles={userTypeRoles} />
          <div className="form-buttons">
            <ScaleButton
              variant="primary"
              disabled={!dirty || !isValid}
            >
              Save
            </ScaleButton>
            <ScaleButton
              type="button"
              variant="secondary"
              style={{ marginLeft: '1em' }}
              onClick={onCancel}
            >
              Cancel
            </ScaleButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};

EditProductMemberForm.propTypes = {
  productMember: PropTypes.shape({
    userId: PropTypes.number,
    username: PropTypes.string,
    createdBy: PropTypes.string,
    productMemberId: PropTypes.number,
    productMemberRole: PropTypes.number,
    isMachineAccount: PropTypes.bool,
  }),
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

EditProductMemberForm.defaultProps = {
  productMember: undefined,
};

export default EditProductMemberForm;
