export const FETCH_PRODUCT_MEMBERS_STARTED = 'FETCH_PRODUCT_MEMBERS_STARTED';
export const FETCH_PRODUCT_MEMBERS_FULLFILLED = 'FETCH_PRODUCT_MEMBERS_FULLFILLED';
export const CREATE_PRODUCT_MEMBER_STARTED = 'CREATE_PRODUCT_MEMBER_STARTED';
export const CREATE_PRODUCT_MEMBER_FULLFILLED = 'CREATE_PRODUCT_MEMBER_FULLFILLED';
export const UPDATE_PRODUCT_MEMBER_STARTED = 'UPDATE_PRODUCT_MEMBER_STARTED';
export const UPDATE_PRODUCT_MEMBER_FULLFILLED = 'UPDATE_PRODUCT_MEMBER_FULLFILLED';
export const DELETE_PRODUCT_MEMBER_STARTED = 'DELETE_PRODUCT_MEMBER_STARTED';
export const DELETE_PRODUCT_MEMBER_FULLFILLED = 'DELETE_PRODUCT_MEMBER_FULLFILLED';

export const fetchProductMembers = (productId) => (
  { type: FETCH_PRODUCT_MEMBERS_STARTED, payload: productId }
);

export const fetchProductMembersFullfilled = (data) => (
  { type: FETCH_PRODUCT_MEMBERS_FULLFILLED, payload: data }
);

export const createProductMember = (data) => (
  { type: CREATE_PRODUCT_MEMBER_STARTED, payload: data }
);

export const createProductMemberFullfilled = (data) => (
  { type: CREATE_PRODUCT_MEMBER_FULLFILLED, payload: data }
);

export const updateProductMember = (data) => (
  { type: UPDATE_PRODUCT_MEMBER_STARTED, payload: data }
);

export const updateProductMemberFullfilled = (data) => (
  { type: UPDATE_PRODUCT_MEMBER_FULLFILLED, payload: data }
);

export const deleteProductMember = (memberId) => (
  { type: DELETE_PRODUCT_MEMBER_STARTED, payload: memberId }
);

export const deleteProductMemberFullfilled = (productId) => (
  { type: DELETE_PRODUCT_MEMBER_FULLFILLED, payload: productId }
);
