import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Container } from '@mui/material';

import Demands from '../../api/demands';
import useApiCall from '../../hooks/useApiCall';
import Table from '../../components/Table';
import CreateModal from './CreateModal';
import rbacCheck from '../../rbac/rbacCheck';


const tableFields = [
  { id: 'title', title: 'Title' },
  { id: 'id', title: 'ID' },
  { id: 'serviceReceivers', title: 'Service Receivers' },
  { id: 'status', title: 'Status' },
];

const DemandsTable = () => {
  const navigate = useNavigate();
  const getDemandsApiCall = useApiCall(Demands.getDemands);
  const [demands, setDemands] = useState([]);
  const [createModalOpened, setCreateModalOpened] = useState(false);
  const roles = useSelector((state) => state.user);
  const canEditDemands = rbacCheck(roles, '/demand:PATCH');

  async function getDemands() {
    const [data] = await getDemandsApiCall();
    if (data) {
      const tableRows = data.map((d) => ({
        key: d.demandId,
        onRowClick: () => navigate(canEditDemands ? `${d.demandId}/edit` : d.demandId),
        data: [
          { field: 'title', value: d.title },
          { field: 'id', value: d.demandId },
          { field: 'actions', value: 'tags', tags: d.serviceReceivers },
          { field: 'status', value: d.status },
        ],
      }));
      setDemands(tableRows);
    }
  }

  useEffect(() => {
    getDemands();
  }, []);

  return (
    <Container maxWidth="lg">
      {createModalOpened && (
        <CreateModal
          opened
          onClose={setCreateModalOpened}
          onSuccess={() => getDemands()}
        />
      )}
      <Table
        header="Demands"
        fields={tableFields}
        rows={demands}
        createAction={{ tooltip: 'Create demand', onClick: () => setCreateModalOpened(true) }}
      />
    </Container>
  );
};


export default DemandsTable;
