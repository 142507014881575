/* eslint-disable react/prop-types */
import { useRef } from 'react';
import { ScaleButton, ScaleModal, ScaleNotification } from '@telekom/scale-components-react';
import { Formik, Form } from 'formik';
import { Grid, Paper } from '@mui/material';
import * as Yup from 'yup';

import Demands from '../../api/demands';
import useApiCall from '../../hooks/useApiCall';
import {
  FormikAutocomplete,
  FormikCheckbox,
  FormikDateField,
  FormikMultilineTextField,
  FormikNumberField,
  FormikSelect,
  FormikTextField,
} from '../../components/Formik/index';


const serviceReceivers = [
  { value: 'AT', label: 'AT' },
  { value: 'CZ', label: 'CZ' },
  { value: 'GR', label: 'GR' },
  { value: 'HR', label: 'HR' },
  { value: 'HU', label: 'HU' },
  { value: 'ME', label: 'ME' },
  { value: 'MK', label: 'MK' },
  { value: 'NL', label: 'NL' },
  { value: 'PL', label: 'PL' },
  { value: 'RO', label: 'RO' },
  { value: 'SK', label: 'SK' },
  { value: 'DE_DTT', label: 'DE-DTT' },
  { value: 'DE_PK', label: 'DE-PK' },
  { value: 'DE_GK', label: 'DE-GK' },
  { value: 'DE_IOT', label: 'DE-IOT' },
  { value: 'VTI_GT', label: 'VTI-GT' },
  { value: 'VTI_IHUB', label: 'VTI-iHUB' },
  { value: 'VEU', label: 'VEU' },
];

const importanceDepartments = [
  { value: 'REGULATORY', label: 'Regulatory' },
  { value: 'LEGAL', label: 'Legal' },
  { value: 'SECURITY', label: 'Security' },
];

const initialValues = {
  title: '',
  description: '',
  serviceReceivers: [],
  deliveryDate: '',
  deliveryNotes: '',
  fte: 0.0,
  fteNotes: '',
  important: false,
  importanceDetails: {
    department: '',
    notes: '',
  },
};


const today = new Date().toISOString().split('T')[0];

const CreateModal = ({ opened, onClose, onSuccess }) => {
  const createDemandApiCall = useApiCall(Demands.createDemand);
  const formRef = useRef(null);
  const importanceRef = useRef(null);

  const createDemand = async (formData, resetForm) => {
    const temp = { ...formData };
    if (!temp.important) delete temp.importanceDetails;

    const [data] = await createDemandApiCall(temp);
    if (data !== null) {
      resetForm();
      onSuccess();
      onClose();
    }
  };

  return (
    <ScaleModal
      heading="New Demand"
      opened={opened}
      onScale-before-close={(event) => {
        event.preventDefault();
        if (event.detail.trigger === 'CLOSE_BUTTON') {
          formRef.current.resetForm();
          onClose();
        }
      }}
    >
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        initialErrors={{
          title: 'Required',
          description: 'Required',
          serviceReceivers: 'Required',
          deliveryDate: 'Required',
        }}
        validationSchema={Yup.object().shape({
          title: Yup.string().required('Required'),
          description: Yup.string().required('Required'),
          serviceReceivers: Yup.array().required('Required').min(1, 'Select at least 1 service receiver'),
          deliveryDate: Yup.date().min(today, 'Date cannot be earlier than today').required('Required'),
          important: Yup.boolean(),
          importanceDetails: Yup.object().when('important', {
            is: true,
            then: () => Yup.object().shape({
              department: Yup.string().required('Department is required'),
              notes: Yup.string().required('Notes are required'),
            }),
            otherwise: (schema) => schema.notRequired(),
          }),
        })}
        onSubmit={(data, formik) => createDemand(data, formik.resetForm)}
      >
        {(props) => (
          <Form>
            <Grid
              container
              flexDirection="column"
              paddingBlock={2}
            >
              <Grid item padding=".5rem 0">
                <FormikTextField name="title" label="Title" />
              </Grid>
              <Grid item padding=".5rem 0">
                <FormikTextField name="description" label="Description" />
              </Grid>
              <Grid item padding=".5rem 0">
                <FormikAutocomplete name="serviceReceivers" label="Service Receivers" options={serviceReceivers} />
              </Grid>
              <Grid item padding=".5rem 0">
                <FormikDateField name="deliveryDate" label="Delivery Date" />
              </Grid>
              <Grid item padding=".5rem 0">
                <FormikMultilineTextField name="deliveryNotes" label="Delivery Notes" />
              </Grid>
              <Grid item padding=".5rem 0">
                <FormikNumberField name="fte" label="FTE" />
              </Grid>
              <Grid item padding=".5rem 0">
                <FormikMultilineTextField name="fteNotes" label="FTE Notes" />
              </Grid>
              <Grid item padding=".5rem 0">
                <FormikCheckbox name="important" label="Mark as important" importanceRef={importanceRef} />
              </Grid>
              <Grid item padding=".5rem 0">
                <Grid container flexDirection="column">
                  <Paper
                    ref={importanceRef}
                    variant="outlined"
                    style={{
                      display: props.values.important ? 'block' : 'none',
                      padding: '.5rem',
                    }}
                  >
                    <Grid item padding=".5rem 0">
                      <ScaleNotification heading=" Mark as important only when truly essential." variant="warning" opened />
                    </Grid>
                    <Grid item padding=".5rem 0">
                      <FormikSelect name="importanceDetails.department" label="Department" menuItems={importanceDepartments} disabled="important" />
                    </Grid>
                    <Grid item padding=".5rem 0">
                      <FormikMultilineTextField name="importanceDetails.notes" label="Importance Notes" disabled="important" />
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
              <Grid item padding=".5rem 0" alignSelf="flex-end">
                <ScaleButton type="submit" disabled={!(props.dirty && props.isValid)}>
                  Submit
                </ScaleButton>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </ScaleModal>
  );
};


export default CreateModal;
