import {
  ScaleButton,
  ScaleIconActionAdd,
  ScalePagination,
  ScaleTable,
  ScaleTextField,
  ScaleSwitch,
  ScaleTag,
} from '@telekom/scale-components-react';
import { Grid, Typography } from '@mui/material';
import { PropTypes } from 'prop-types';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  BehaviorSubject, debounceTime, map,
} from 'rxjs';
import { useSelector } from 'react-redux';
import { regexValidator } from '../../helpers/regexValidator';

const rowActions = (action) => {
  switch (action.value) {
    case 'switch':
      return <ScaleSwitch color="primary" size="small" {...action.props} />;
    case 'tags':
      return action.tags ? (
        <div>
          {action.tags.map((tag) => (
            <ScaleTag
              type="strong"
              style={{ marginRight: '0.3em' }}
              size="small"
              key={tag}
            >
              {tag}
            </ScaleTag>
          ))}
        </div>
      ) : null;
    default: return action.value;
  }
};

const PaginatedTable = ({
  header,
  fields,
  rows,
  totalItems,
  tableSize,
  onPagination,
  onSearch,
  // onRowClick,
  onCreateClick,
}) => {
  const [offset, setOffset] = useState(0);
  const [search, setSearch] = useState('');
  const isLoading = useSelector((state) => state.status.loading);
  const [invalidSearch, setInvalidSearch] = useState(false);

  const search$ = new BehaviorSubject(search);

  const searchObservable = search$.pipe(
    debounceTime(300),
    map((searchText) => {
      if (regexValidator.name.test(searchText)) {
        setInvalidSearch(false);
        return searchText;
      }
      setInvalidSearch(true);
      return '';
    }),
  );

  const handlePagination = (e) => {
    const startPos = e.detail.startElement;
    setOffset(startPos);
    onPagination(startPos);
  };

  useEffect(() => {
    const sub = searchObservable.subscribe({
      next: (searchText) => {
        setOffset(0);
        onPagination(0);
        onSearch(searchText);
      },
    });

    return () => sub.unsubscribe();
  }, [search]);

  const intl = useIntl();
  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        paddingBottom="1rem"
      >
        <Grid item>
          <Typography variant="h4" color="primary">{header}</Typography>
        </Grid>
        <Grid item>
          <Grid
            container
            justifyContent="flex-end"
            alignItems="center"
            columnSpacing={1}
          >
            <Grid item>
              <ScaleTextField
                label="Search"
                type="search"
                inputModeType="search"
                invalid={invalidSearch}
                helperText={invalidSearch ? 'All lower-case, no spaces, dash only' : ''}
                onScale-change={(e) => setSearch(e.detail.value)}
                style={{ marginRight: onCreateClick ? '1rem' : '0' }}
              />
            </Grid>
            {onCreateClick && (
              <Grid item>
                <ScaleButton title="Create" onClick={onCreateClick}>
                  <ScaleIconActionAdd />
                  Create
                </ScaleButton>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <ScaleTable>
        <table>
          <thead>
            <tr style={{ textAlign: 'center' }}>
              {fields.map((field) => (
                <th
                  id={field.id}
                  key={field.id}
                  style={{
                    minWidth: '10rem',
                  }}
                >
                  {field.title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            { !isLoading && rows.length > 0 ? (
              rows.map((row) => (
                <tr
                  style={{ cursor: 'pointer' }}
                  key={row.key}
                  onClick={row.onRowClick ? () => row.onRowClick(row) : null}
                >
                  {row.data.map((cell) => (cell.field !== 'actions'
                    ? (<td key={cell.field + cell.value} style={{ textAlign: 'left' }}>{cell.value}</td>
                    ) : (
                      <td key={cell.field + cell.value} style={{ textAlign: 'left' }}>{rowActions(cell)}</td>
                    )))}
                </tr>
              ))
            ) : !isLoading && (
              <tr>
                <td colSpan={fields.length} style={{ textAlign: 'center', padding: '3rem' }}>
                  {intl.formatMessage({ id: 'EmptyTable' }) }
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </ScaleTable>

      <ScalePagination
        style={{
          display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '1rem',
        }}
        pageSize={tableSize}
        startElement={offset}
        totalElements={totalItems}
        hideBorder
        onScale-pagination={handlePagination}
      />
    </>
  );
};
PaginatedTable.propTypes = {
  header: PropTypes.string,
  fields: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  })).isRequired,
  onPagination: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  totalItems: PropTypes.number.isRequired,
  tableSize: PropTypes.number.isRequired,
  rows: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.any,
  ])).isRequired,
  onCreateClick: PropTypes.func,
};
PaginatedTable.defaultProps = {
  header: '',
  onCreateClick: null,
};
export default PaginatedTable;
