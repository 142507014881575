import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { ScaleButton } from '@telekom/scale-components-react';
import {
  Formik,
  Form,
} from 'formik';
import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import Auth from '../../../../api/auth';
import {
  FormikSelect,
  FormikTextField,
} from '../../../../components/Formik/index';
import useApiCall from '../../../../hooks/useApiCall';

const EditProjectForm = ({ project, onSubmit, onCancel }) => {
  const fetchBusinessUnits = useApiCall(Auth.fetchV1BusinessUnits);
  const fetchUsers = useApiCall(Auth.fetchV2Users);
  const [businessUnits, setBusinessUnits] = useState(null);
  const [technicalLeads, setTechnicalLeads] = useState(null);

  async function getBusinessUnits() {
    const [data] = await fetchBusinessUnits({ organizationId: project.organizationId });
    if (data) setBusinessUnits(data);
  }

  async function getTechnicalLeads() {
    const [data] = await fetchUsers({ organizationId: project.organizationId, roles: ['TECHNICAL_LEAD'] });
    if (data) setTechnicalLeads(data);
  }

  useEffect(() => {
    getBusinessUnits();
    getTechnicalLeads();
  }, []);

  const initialValues = {
    description: project.description,
    businessUnitId: project.businessUnitId,
    technicalLeadId: project.technicalLeadId,
  };

  const validationSchema = Yup.object().shape({
    description: Yup.string().required('Required'),
  });

  const businessUnitsItems = businessUnits?.map((businessUnit) => ({
    value: businessUnit._id,
    label: businessUnit.name,
  }));

  const technicalLeadsItems = technicalLeads?.map((technicalLead) => ({
    value: technicalLead._id,
    label: technicalLead.email,
  }));

  return (
    <Formik
      enableReinitialize
      validateOnChange
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ dirty, isValid }) => (
        <Form>
          <Grid
            container
            gap={2}
            py={2}
          >
            <Grid item xs={12}>
              <FormikTextField
                name="description"
                label="Description"
              />
            </Grid>
            <Grid item xs={12}>
              <FormikSelect
                name="businessUnitId"
                label="Business Unit"
                menuItems={businessUnitsItems}
              />
            </Grid>
            <Grid item xs={12}>
              <FormikSelect
                name="technicalLeadId"
                label="Technical Lead"
                menuItems={technicalLeadsItems}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                justifyContent="flex-end"
                gap={1}
              >
                <Grid item>
                  <ScaleButton
                    variant="primary"
                    disabled={!dirty || !isValid}
                  >
                    Save
                  </ScaleButton>
                </Grid>
                <Grid item>
                  <ScaleButton
                    type="button"
                    variant="secondary"
                    onClick={onCancel}
                  >
                    Cancel
                  </ScaleButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

EditProjectForm.propTypes = {
  project: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.object,
    ]),
  ).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default EditProjectForm;
