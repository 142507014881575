import {
  FETCH_PRODUCTS_FULLFILLED,
  CREATE_PRODUCT_FULLFILLED,
  UPDATE_PRODUCT_FULLFILLED,
  DELETE_PRODUCT_FULLFILLED,
} from './actions';

const productsAdminReducer = (state = [], action) => {
  switch (action.type) {
    case CREATE_PRODUCT_FULLFILLED:
      return [...state, action.payload];
    case UPDATE_PRODUCT_FULLFILLED:
      return state.map((product) => {
        if (product._id === action.payload._id) {
          return action.payload;
        }
        return product;
      });
    case FETCH_PRODUCTS_FULLFILLED:
      return action.payload;
    case DELETE_PRODUCT_FULLFILLED:
      return state.filter((product) => product._id !== action.payload._id);
    default:
      return state;
  }
};

export { productsAdminReducer };
