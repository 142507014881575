import { useState } from 'react';
import { Outlet, ScrollRestoration } from 'react-router-dom';
import {
  ScaleModal,
  ScaleTelekomAppShell,
  ScaleTelekomFooter,
  ScaleTelekomHeader,
} from '@telekom/scale-components-react';

import PropTypes from 'prop-types';
import Authenticator from './components/SecureRoutes/Authenticator';
import CookiesCheck from './pages/CookiesSettings/CookiesCheck';
import useNotifier from './hooks/useNotifier';
import FooterContent from './components/AppLayout/FooterContent';
import Overlay from './components/Overlay';
import MainNavigation from './components/AppLayout/MainNavigation';
import FunctionNavigation from './components/AppLayout/FunctionNavigation';
import ScrollToTop from './components/AppLayout/ScrollToTop';

import './App.css';

const AppLayout = ({ switchMode }) => {
  useNotifier();
  const [cookiesModalOpened, setCookiesModalOpened] = useState(false);

  return (
    <>
      {/* We need ScrollToTop in addition to ScrollRestoration because the latter
      misbehaves in some cases. */}
      <ScrollToTop />
      <ScrollRestoration />
      <Overlay />
      <ScaleModal
        opened={cookiesModalOpened}
        size="small"
        heading="Your Cookies settings"
      >
        <CookiesCheck excludedPaths={['/data-privacy', '/cookies-settings']} setCookiesModalOpened={setCookiesModalOpened} />
      </ScaleModal>
      <ScaleTelekomAppShell>
        <ScaleTelekomHeader
          slot="header"
          app-name="TDI Connect"
          app-name-link="/"
          logoHref="/"
          type="slim"
        >
          <MainNavigation />
          <FunctionNavigation switchMode={switchMode} />
        </ScaleTelekomHeader>

        <div className="app-content">
          <Authenticator>
            <Outlet />
          </Authenticator>
        </div>

        <ScaleTelekomFooter slot="footer" type="minimal">
          <FooterContent />
        </ScaleTelekomFooter>
      </ScaleTelekomAppShell>
    </>
  );
};

AppLayout.propTypes = {
  switchMode: PropTypes.func.isRequired,
};

export default AppLayout;
