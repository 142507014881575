import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

import { convertUnits } from '../../../../helpers/unitConversion';

import MonitoringMs from '../../../../api/monitoring';
import useApiCall from '../../../../hooks/useApiCall';

const Stat = ({ panelInfo, dateRange }) => {
  const [value, setValue] = useState('No data');
  const getPanelDataApiCall = useApiCall(MonitoringMs.getPanel);


  const getPanel = async (targets) => {
    const [data] = await getPanelDataApiCall(targets);
    if (data) {
      const { refId } = panelInfo.targets.filter((target) => !target.hide)[0];
      const panelData = data.results[refId].frames[0].data.values;
      setValue(convertUnits(panelData[panelData.length - 1][0], panelInfo.unit));
    }
  };

  useEffect(() => {
    const toDate = new Date(dateRange.to).getTime();
    const fromDate = new Date(dateRange.from).getTime();
    if (panelInfo.targets) {
      getPanel({ queries: panelInfo.targets, to: toDate.toString(), from: fromDate.toString() });
    }
  }, [dateRange]);

  return <Typography variant="h1" color="primary" style={{ margin: '2.7rem' }}>{value}</Typography>;
};

Stat.propTypes = {
  panelInfo: PropTypes.shape({
    title: PropTypes.string,
    targets: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
    unit: PropTypes.string,
  }).isRequired,
  dateRange: PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.string,
  }).isRequired,
};


export default Stat;
