import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {
  Formik,
  Form,
  useFormikContext,
  useField,
} from 'formik';
import { ScaleButton, ScaleTextField } from '@telekom/scale-components-react';

import { createProductType } from '../../../../../redux/safescarf/productTypes/actions';

const NameField = () => {
  const { setFieldTouched } = useFormikContext();
  const [field, meta] = useField({ name: 'name' });
  return (
    <div className="form-field">
      <ScaleTextField
        {...field}
        label="Name"
        helperText={meta.touched ? meta.error : null}
        onScale-change={(e) => {
          field.onChange(e);
          setFieldTouched('name');
        }}
      />
    </div>
  );
};

const DescriptionField = () => {
  const { setFieldTouched } = useFormikContext();
  const [field, meta] = useField({ name: 'description' });
  return (
    <div className="form-field">
      <ScaleTextField
        {...field}
        label="Description"
        helperText={meta.touched ? meta.error : null}
        onScale-change={(e) => {
          field.onChange(e);
          setFieldTouched('description');
        }}
      />
    </div>
  );
};

const CreateProductTypeForm = ({ onSubmit, onCancel }) => {
  const dispatch = useDispatch();
  const initialValues = {
    name: '',
    description: '',
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
  });

  const submit = async (formValues) => {
    dispatch(createProductType({ ...formValues }));
    onSubmit();
  };

  return (
    <Formik
      enableReinitialize
      validateOnChange
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={submit}
    >
      {({ dirty, isValid }) => (
        <Form>
          <NameField />
          <DescriptionField />
          <div className="form-buttons">
            <ScaleButton
              variant="primary"
              disabled={!dirty || !isValid}
            >
              Save
            </ScaleButton>
            <ScaleButton
              type="button"
              variant="secondary"
              style={{ marginLeft: '1em' }}
              onClick={onCancel}
            >
              Cancel
            </ScaleButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};

CreateProductTypeForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};


export default CreateProductTypeForm;
