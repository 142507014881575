import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ScaleButton } from '@telekom/scale-components-react';
import { Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import ProductUnassignment from './ProductUnassignment';
import useApiCall from '../../../hooks/useApiCall';
import Auth from '../../../api/auth';

const DeleteUserForm = ({ onSubmit, onCancel, user }) => {
  const intl = useIntl();
  const [userResources, setUserResources] = useState({});
  const getUserResourcesCall = useApiCall(Auth.fetchV1UserResources);

  const getUserResources = async () => {
    if (!userResources.products?.length) {
      const [data] = await getUserResourcesCall(user.uuid);
      setUserResources(data);
    }
  };
  useEffect(() => {
    getUserResources();
  }, []);

  return (
    <>
      <Typography gutterBottom>{`${intl.formatMessage({ id: 'userDeleteConfirm' })} ${user.email} ?`}</Typography>
      {userResources.products?.length ? (
        <ProductUnassignment resources={userResources} />
      ) : null }
      <div style={{ display: 'flex', justifyContent: 'end', marginBottom: '1em' }}>
        <ScaleButton
          variant="primary"
          onClick={onSubmit}
        >
          Delete
        </ScaleButton>
        <ScaleButton
          type="button"
          variant="secondary"
          style={{ marginLeft: '1em' }}
          onClick={() => {
            onCancel();
          }}
        >
          Cancel
        </ScaleButton>
      </div>

    </>
  );
};

DeleteUserForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  user: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }).isRequired,
};

export default DeleteUserForm;
