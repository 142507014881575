import { ofType, combineEpics } from 'redux-observable';
import { mergeMap, catchError } from 'rxjs/operators';
import { from, of } from 'rxjs';
import Auth from '../../../api/auth';
import {
  FETCH_PRODUCTS_STARTED,
  fetchProductsFullfilled,
  CREATE_PRODUCT_STARTED,
  createProductFullfilled,
  UPDATE_PRODUCT_STARTED,
  updateProductFullfilled,
  DELETE_PRODUCT_STARTED,
  deleteProductFullfilled,
} from './actions';
import { enqueueSnackbar } from '../../notifier/actions';
import { requestFailed } from '../../status/actions';

const fetchProductsEpic = (action$) => action$.pipe(
  ofType(FETCH_PRODUCTS_STARTED),
  mergeMap((action) => from(Auth.fetchV2Products(action.payload)).pipe(
    mergeMap((response) => of(
      fetchProductsFullfilled(response.data),
    )),
    catchError((error) => of(
      requestFailed(),
      enqueueSnackbar({
        message: error.message,
        options: { variant: 'error' },
      }),
    )),
  )),
);

const createProductEpic = (action$) => action$.pipe(
  ofType(CREATE_PRODUCT_STARTED),
  mergeMap((action) => from(Auth.createV2Product(action.payload)).pipe(
    mergeMap((response) => of(
      createProductFullfilled(response.data),
      enqueueSnackbar({
        message: 'productCreation',
        values: { name: response.data.name },
        options: { variant: 'success' },
      }),
    )),
    catchError((error) => of(
      requestFailed(),
      enqueueSnackbar({
        message: error.message,
        options: { variant: 'error' },
      }),
    )),
  )),
);

const updateProductEpic = (action$) => action$.pipe(
  ofType(UPDATE_PRODUCT_STARTED),
  mergeMap((action) => from(Auth.updateV2Product(action.payload)).pipe(
    mergeMap((response) => of(
      updateProductFullfilled(response.data),
      enqueueSnackbar({
        message: 'productUpdated',
        values: { name: response.data.name },
        options: { variant: 'success' },
      }),
    )),
    catchError((error) => of(
      requestFailed(),
      enqueueSnackbar({
        message: error.message,
        options: { variant: 'error' },
      }),
    )),
  )),
);

const deleteProductEpic = (action$) => action$.pipe(
  ofType(DELETE_PRODUCT_STARTED),
  mergeMap((action) => from(Auth.deleteV2Product(action.payload)).pipe(
    mergeMap((response) => of(
      deleteProductFullfilled(response.data),
      enqueueSnackbar({
        message: 'productDeleted',
        values: { name: response.data.name },
        options: { variant: 'success' },
      }),
    )),
    catchError((error) => of(
      requestFailed(),
      enqueueSnackbar({
        message: error.message,
        options: { variant: 'error' },
      }),
    )),
  )),
);

export default combineEpics(
  fetchProductsEpic,
  createProductEpic,
  updateProductEpic,
  deleteProductEpic,
);
