import { Container } from '@mui/material';

import MarkdownFromFile from '../../../components/MarkdownFromFile/MarkdownFromFile';
import webShieldSpecification from '../../../markdowns/WebShield/specification.md';

const WebShieldSpecification = () => (
  <Container maxWidth="lg">
    <MarkdownFromFile file={webShieldSpecification} />
  </Container>
);

export default WebShieldSpecification;
