import {
  CREATE_PRODUCT_TYPE_FULLFILLED,
  FETCH_PRODUCT_TYPES_FULLFILLED,
  UPDATE_PRODUCT_TYPE_FULLFILLED,
} from './actions';


const productTypesReducer = (state = [], action) => {
  switch (action.type) {
    case CREATE_PRODUCT_TYPE_FULLFILLED:
      return [...state, action.payload];
    case FETCH_PRODUCT_TYPES_FULLFILLED:
      return action.payload;
    case UPDATE_PRODUCT_TYPE_FULLFILLED:
      return state.map((productType) => {
        if (productType.id === action.payload.id) {
          return action.payload;
        }
        return productType;
      });
    default:
      return state;
  }
};

export { productTypesReducer };

