export const FETCH_SAFESCARF_USERS_STARTED = 'FETCH_SAFESCARF_USERS_STARTED';
export const FETCH_SAFESCARF_USERS_FULLFILLED = 'FETCH_SAFESCARF_USERS_FULLFILLED';
export const CREATE_SAFESCARF_USER_STARTED = 'CREATE_SAFESCARF_USER_STARTED';
export const CREATE_SAFESCARF_USER_FULLFILLED = 'CREATE_SAFESCARF_USER_FULLFILLED';
export const UPDATE_SAFESCARF_USER_STARTED = 'UPDATE_SAFESCARF_USER_STARTED';
export const UPDATE_SAFESCARF_USER_FULLFILLED = 'UPDATE_SAFESCARF_USER_FULLFILLED';

export const fetchUsers = (params) => (
  { type: FETCH_SAFESCARF_USERS_STARTED, payload: params }
);

export const fetchUsersFullfilled = (data) => (
  { type: FETCH_SAFESCARF_USERS_FULLFILLED, payload: data }
);

export const createUser = (data) => (
  { type: CREATE_SAFESCARF_USER_STARTED, payload: data }
);

export const createUserFullfilled = (data) => (
  { type: CREATE_SAFESCARF_USER_FULLFILLED, payload: data }
);

export const updateUser = (data) => (
  { type: UPDATE_SAFESCARF_USER_STARTED, payload: data }
);

export const updateUserFullfilled = (data) => (
  { type: UPDATE_SAFESCARF_USER_FULLFILLED, payload: data }
);
