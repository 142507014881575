import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Gauge } from '@mui/x-charts';

import MonitoringMs from '../../../../api/monitoring';
import useApiCall from '../../../../hooks/useApiCall';

const GaugeComponent = ({ panelInfo, dateRange }) => {
  const [value, setValue] = useState(100);
  const getPanelDataApiCall = useApiCall(MonitoringMs.getPanel);

  const getPanel = async (targets) => {
    const [data] = await getPanelDataApiCall(targets);
    if (data) {
      const { refId } = panelInfo.targets.filter((target) => !target.hide)[0];
      const panelData = data.results[refId]?.frames[0].data.values;
      if (panelData)setValue(panelData[panelData.length - 1][0]);
    }
  };

  useEffect(() => {
    const toDate = new Date(dateRange.to).getTime();
    const fromDate = new Date(dateRange.from).getTime();
    const targets = panelInfo.targets.filter((target) => !target.hide);
    if (targets) {
      getPanel({ queries: targets, to: toDate.toString(), from: fromDate.toString() });
    }
  }, []);

  return (
    <Gauge
      value={value}
      text={`${value.toFixed(2)} %`}
      width={200}
      height={340}
      startAngle={-90}
      endAngle={90}
    />
  );
};

GaugeComponent.propTypes = {
  panelInfo: PropTypes.shape({
    title: PropTypes.string,
    targets: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
    repeat: PropTypes.string,
  }).isRequired,
  dateRange: PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.string,
  }).isRequired,
};


export default GaugeComponent;
