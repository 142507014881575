import { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';

import Table from '../../../../components/Table';
import useApiCall from '../../../../hooks/useApiCall';
import Auth from '../../../../api/auth';

function getHeader(type) {
  return `${type.split()?.map((el) => el[0].toUpperCase() + el.substr(1))} Products`;
}

const UserProducts = ({ type }) => {
  const navigate = useNavigate();
  const productsTypeFilterMap = {
    caas: ['caasonopenstack'],
    compute: ['evaluation', 'compute'],
  };

  const [products, setProducts] = useState([]);
  const getProductsCall = useApiCall(Auth.fetchV2Products);

  const getProducts = async () => {
    const [data] = await getProductsCall({ type: productsTypeFilterMap[type] });
    if (data) setProducts(data);
  };

  useEffect(() => {
    getProducts();
  }, [type]);

  const fields = [
    { title: 'Name', id: 'name' },
    { title: 'Description', id: 'description' },
    { title: 'Type', id: 'type' },
    { title: 'Project', id: 'project' },
    { title: 'Juicegroup', id: 'juicegroup' },
    { title: 'Approved', id: 'approved' },
  ];

  const rows = products.map((p) => ({
    key: p._id,
    onRowClick: () => navigate(p._id),
    data: [
      {
        field: 'name',
        value: p.name,
      },
      {
        field: 'description',
        value: p.description,
      },
      {
        field: 'type',
        value: p.marketplaceItem.name,
      },
      {
        field: 'project',
        value: p.project.name,
      },
      {
        field: 'juicegroup',
        value: p.details.juiceGroup,
      },
      {
        field: 'approved',
        value: p.approved ? 'True' : 'False',
      },
    ],
  }));

  return (
    <Grid
      container
      direction="column"
      padding="2rem 10rem"
    >
      <Grid item>
        <Table
          header={getHeader(type)}
          fields={fields}
          rows={rows}
        />
      </Grid>
    </Grid>
  );
};

UserProducts.propTypes = {
  type: PropTypes.oneOf(['compute', 'caas']).isRequired,
};


export default UserProducts;
