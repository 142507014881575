import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Table from '../../../../components/Table';

import MonitoringMs from '../../../../api/monitoring';
import useApiCall from '../../../../hooks/useApiCall';

const mapTableData = (panelData, fields) => panelData.map((row, index) => ({
  key: index.toString(),
  data: fields.map((field) => {
    if (typeof row[field.id] === 'number') {
      return {
        field: 'actions',
        value: 'progressBar',
        props: {
          percentage: Math.floor(row[field.id] * 1000) / 1000,
        },
      };
    }
    return {
      field: field.id,
      value: row[field.id],
    };
  }),
}));

const MonitoringTable = ({ panelInfo, dateRange }) => {
  const [value, setValue] = useState({ fields: [], data: [] });
  const getPanelDataApiCall = useApiCall(MonitoringMs.getPanel);

  const getPanel = async (targets) => {
    const [data] = await getPanelDataApiCall(targets);
    if (data) {
      const { refId } = panelInfo.targets.filter((target) => !target.hide)[0];
      const panelData = data.results[refId]?.frames[0];
      const transformations = panelInfo.transformations.find((transformation) => transformation.id === 'organize').options;

      const formatedData = panelData.data.values[0].map(
        (_, i) => panelData.data.values.map((row) => row[i]).reduce((acc, v, index) => {
          acc[panelData.schema.fields[index].name] = v;
          return acc;
        }, {}),
      );

      const fields = transformations ? Object.keys(transformations.indexByName).map((key) => ({
        id: key, title: transformations.renameByName[key],
      })).sort(
        (a, b) => transformations.indexByName[a.id] - transformations.indexByName[b.id],
      ) : [];


      setValue({
        fields,
        data: mapTableData(formatedData, fields),
      });
    }
  };

  useEffect(() => {
    const toDate = new Date(dateRange.to).getTime();
    const fromDate = new Date(dateRange.from).getTime();
    const targets = panelInfo.targets.filter((target) => !target.hide);
    if (targets) {
      getPanel({ queries: targets, to: toDate.toString(), from: fromDate.toString() });
    }
  }, []);

  return (
    <Table
      fields={value.fields}
      rows={value.data}
      pageSize={3}
    />
  );
};

MonitoringTable.propTypes = {
  panelInfo: PropTypes.shape({
    title: PropTypes.string,
    targets: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
    transformations: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  }).isRequired,
  dateRange: PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.string,
  }).isRequired,
};


export default MonitoringTable;
