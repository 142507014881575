import {
  CREATE_PRODUCT_MEMBER_FULLFILLED,
  FETCH_PRODUCT_MEMBERS_FULLFILLED,
  UPDATE_PRODUCT_MEMBER_FULLFILLED,
  DELETE_PRODUCT_MEMBER_FULLFILLED,
} from './actions';

const productMembersReducer = (state = [], action) => {
  switch (action.type) {
    case FETCH_PRODUCT_MEMBERS_FULLFILLED: {
      return action.payload;
    }
    case CREATE_PRODUCT_MEMBER_FULLFILLED:
      return [...state, action.payload];
    case UPDATE_PRODUCT_MEMBER_FULLFILLED:
      return state.map((member) => {
        if (member.id === action.payload.id) {
          return action.payload;
        }
        return member;
      });
    case DELETE_PRODUCT_MEMBER_FULLFILLED:
      return state.filter((member) => member.id !== action.payload);
    default:
      return state;
  }
};

export { productMembersReducer };

