import { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Provider } from 'react-redux';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { SnackbarProvider } from 'notistack';
import { defineCustomElements } from '@telekom/scale-components/loader';
import '@telekom/scale-components/dist/scale-components/scale-components.css';

import ConnectedIntlProvider from './i18n/ConnectedIntlProvider';
import createReduxStore from './redux/store';
import App from './App';

import * as serviceWorker from './serviceWorker';
import './index.css';

import keycloak from './keycloak';

import http from './api/httpClient';

defineCustomElements(window);

Sentry.init({
  dsn: window.PORTAL_SENTRY_UI_DSN || import.meta.env.VITE_SENTRY_UI_DSN,
  maxBreadcrumbs: 50,
  environment: window.PORTAL_ENV || import.meta.env.VITE_ENV,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
  ],
  tracesSampleRate: Number(window.PORTAL_SENTRY_TRACES_SAMPLE_RATE)
  || Number(import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE),
  tracePropagationTargets: window.PORTAL_SENTRY_TRACE_PROPAGATION_TARGETS
  || import.meta.env.VITE_SENTRY_TRACE_PROPAGATION_TARGETS,
  normalizeDepth: 10,
});

function onKeycloakEvent(event) {
  if (event === 'onAuthSuccess') {
    http.interceptors.request.use(async (config) => {
      const { headers } = config;
      headers.Authorization = `Bearer ${keycloak.token}`;
      return { ...config, headers };
    });
  }
}

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <ReactKeycloakProvider
    authClient={keycloak}
    initOptions={{
      onLoad: 'check-sso',
      checkLoginIframe: false,
      silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
      // silentCheckSsoFallback: false,
    }}
    onEvent={onKeycloakEvent}
  >
    <Provider store={createReduxStore()}>
      <ConnectedIntlProvider>
        <SnackbarProvider anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
          <App />
        </SnackbarProvider>
      </ConnectedIntlProvider>
    </Provider>
  </ReactKeycloakProvider>,
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
