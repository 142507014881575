import { ofType } from 'redux-observable';
import { mergeMap, catchError } from 'rxjs/operators';
import { from, of } from 'rxjs';

import Safescarf from '../../../api/safescarf';

import {
  FETCH_ROLES_STARTED,
  fetchRolesFullfilled,
} from './actions';
import { enqueueSnackbar } from '../../notifier/actions';
import { requestFailed } from '../../status/actions';

const fetchRolesEpic = (action$) => action$.pipe(
  ofType(FETCH_ROLES_STARTED),
  mergeMap(() => from(Safescarf.fetchSafescarfRoles()).pipe(
    mergeMap((response) => of(
      fetchRolesFullfilled(response.data),
    )),
    catchError((error) => of(
      requestFailed(),
      enqueueSnackbar({
        message: error.message,
        options: { variant: 'error' },
      }),
    )),
  )),
);

export default fetchRolesEpic;
