import { ofType, combineEpics } from 'redux-observable';
import { mergeMap, catchError } from 'rxjs/operators';
import { from, of } from 'rxjs';
import Auth from '../../../api/auth';
import {
  FETCH_ORGANIZATIONS_STARTED,
  fetchOrganizationsFullfilled,
  CREATE_ORGANIZATION_STARTED,
  createOrganizationFullfilled,
  UPDATE_ORGANIZATION_STARTED,
  updateOrganizationFullfilled,
  ACTIVATE_MARKETPLACEITEM_STARTED,
  activateMarketplaceItemFullfilled,

} from './actions';

import { enqueueSnackbar } from '../../notifier/actions';
import { requestFailed } from '../../status/actions';

const fetchOrganizationsEpic = (action$) => action$.pipe(
  ofType(FETCH_ORGANIZATIONS_STARTED),
  mergeMap((action) => from(Auth.fetchV1Organizations(action.payload)).pipe(
    mergeMap((response) => of(
      fetchOrganizationsFullfilled(response.data),
    )),
    catchError((error) => of(
      requestFailed(),
      enqueueSnackbar({
        message: error.message,
        options: { variant: 'error' },
      }),
    )),
  )),
);

const createOrganizationEpic = (action$) => action$.pipe(
  ofType(CREATE_ORGANIZATION_STARTED),
  mergeMap((action) => from(Auth.createV1Organization(action.payload)).pipe(
    mergeMap((response) => of(
      createOrganizationFullfilled(response.data),
      enqueueSnackbar({
        message: 'organizationCreation',
        values: { enterpriseName: response.data.enterpriseName },
        options: { variant: 'success' },
      }),
    )),
    catchError((error) => of(
      requestFailed(),
      enqueueSnackbar({
        message: error.message,
        options: { variant: 'error' },
      }),
    )),
  )),
);

const updateOrganizationEpic = (action$) => action$.pipe(
  ofType(UPDATE_ORGANIZATION_STARTED),
  mergeMap((action) => from(Auth.updateV1Organization(action.payload)).pipe(
    mergeMap((response) => of(
      updateOrganizationFullfilled(response.data),
      enqueueSnackbar({
        message: 'organizationUpdated',
        options: { variant: 'success' },
      }),
    )),
    catchError((error) => of(
      requestFailed(),
      enqueueSnackbar({
        message: error.message,
        options: { variant: 'error' },
      }),
    )),
  )),
);

const activateMarketplaceItemEpic = (action$) => action$.pipe(
  ofType(ACTIVATE_MARKETPLACEITEM_STARTED),
  mergeMap((action) => from(Auth.activateMarketplaceItem(action.payload)).pipe(
    mergeMap((response) => of(
      activateMarketplaceItemFullfilled(response.data),
      enqueueSnackbar({
        message: 'organizationUpdated',
        options: { variant: 'success' },
      }),
    )),
    catchError((error) => of(
      requestFailed(),
      enqueueSnackbar({
        message: error.message,
        options: { variant: 'error' },
      }),
    )),
  )),
);

export default combineEpics(
  fetchOrganizationsEpic,
  createOrganizationEpic,
  updateOrganizationEpic,
  activateMarketplaceItemEpic,
);
