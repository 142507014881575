import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Markdown from 'markdown-to-jsx';
import { Typography } from '@mui/material';

const markdownOptions = {
  forceBlock: true,
  overrides: {
    h1: {
      component: Typography,
      props: {
        variant: 'h4',
        color: 'primary',
        gutterBottom: true,
      },
    },
    h2: {
      component: Typography,
      props: {
        variant: 'h5',
        color: 'primary',
        gutterBottom: true,
      },
    },
    h3: {
      component: Typography,
      props: {
        variant: 'h6',
        color: 'primary',
        gutterBottom: true,
      },
    },
    h4: {
      component: Typography,
      props: {
        variant: 'subtitle1',
        color: 'primary',
        gutterBottom: true,
      },
    },
    h5: {
      component: Typography,
      props: {
        variant: 'subtitle2',
        gutterBottom: true,
      },
    },
    p: {
      component: Typography,
      props: {
        variant: 'body1',
        paragraph: true,
      },
    },
    li: {
      component: Typography,
      props: {
        component: 'li',
      },
    },
    td: {
      component: Typography,
      props: {
        component: 'td',
        variant: 'body1',
        style: {
          border: '1px solid #c9c9c9',
          padding: '0 1rem 0 .5rem',
        },
      },
    },
    th: {
      component: Typography,
      props: {
        component: 'th',
        variant: 'body',
        style: {
          border: '1px solid #c9c9c9',
          padding: '0 1rem 0 .5rem',
        },
      },
    },
    table: {
      props: {
        style: { marginBottom: 15 },
      },
    },
  },
};

const readFile = async (file) => {
  const response = await fetch(file);
  return response.text();
};

const MarkdownFromFile = ({ file }) => {
  const [content, setContent] = useState();

  useEffect(() => {
    const fetchContent = async () => {
      const fileContent = await readFile(file);
      setContent(fileContent);
    };

    fetchContent();
  }, [file]); // Only re-run the effect if `file` changes


  return (content
    ? <Markdown options={markdownOptions}>{content}</Markdown>
    : null
  );
};

MarkdownFromFile.propTypes = { file: PropTypes.string.isRequired };

export default MarkdownFromFile;
