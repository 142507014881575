import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Markdown from 'markdown-to-jsx';
import { Typography } from '@mui/material';
import {
  ScaleAccordion, ScaleCollapsible, ScaleCard,
} from '@telekom/scale-components-react';

import DateTimeFilter from './DateTimeFilter';
import Stat from './Stat';
import BarChartComponent from './BarChart';
import TimeSeries from './TimeSeries';
import GaugeComponent from './Gauge';
import MonitoringTable from './Table';

import MonitoringMs from '../../../../api/monitoring';
import useApiCall from '../../../../hooks/useApiCall';


const MonitoringComponents = ({ panelInfo, dateRange }) => {
  if (panelInfo.type === 'text') {
    return (
      <Markdown>{panelInfo.content}</Markdown>
    );
  }
  if (panelInfo.type === 'stat') {
    return (
      <Stat panelInfo={panelInfo} dateRange={dateRange} />
    );
  }
  if (panelInfo.type === 'barchart') {
    return (
      <BarChartComponent
        panelInfo={panelInfo}
        dateRange={dateRange}
      />
    );
  }
  if (panelInfo.type === 'timeseries') {
    return (
      <TimeSeries panelInfo={panelInfo} dateRange={dateRange} />
    );
  }
  if (panelInfo.type === 'gauge') {
    return (
      <GaugeComponent panelInfo={panelInfo} dateRange={dateRange} />
    );
  }
  if (panelInfo.type === 'table') {
    return (
      <MonitoringTable panelInfo={panelInfo} dateRange={dateRange} />
    );
  }
  return null;
};

MonitoringComponents.propTypes = {
  panelInfo: PropTypes.shape({
    type: PropTypes.string,
    title: PropTypes.string,
    content: PropTypes.string,
  }).isRequired,
  dateRange: PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.string,
  }).isRequired,
};

// will be fixed in the next iteration
const notWorkingPanels = [
  '# of Instances per deployment type',
  'NODES Total', 'VOLUMES Used/Total',
  'PODS Total', 'PODS CPUs Used',
  'PODS Memory Used',
  '# of Sites per operational model',
  'Cloud Instances per Country',
];

const nowDate = new Date().toISOString().slice(0, 16);
const nowMinusSixMonths = new Date(
  new Date().setMonth(new Date().getMonth() - 6),
).toISOString().slice(0, 16);

const Monitoring = () => {
  const [dashboardData, setDashboardData] = useState();
  const [dateRange, setDateRange] = useState({
    from: nowMinusSixMonths,
    to: nowDate,
  });
  const getDashboardApiCall = useApiCall(MonitoringMs.getDashboard);
  const getDashboard = async () => {
    const [data] = await getDashboardApiCall();
    if (data) { setDashboardData(data); }
  };

  useEffect(() => {
    getDashboard();
  }, []);

  return dashboardData ? (
    <>
      <h1>{dashboardData.title}</h1>
      <DateTimeFilter setDateRange={setDateRange} />

      {dashboardData.rows.map((row) => (
        <ScaleAccordion key={row.title} expanded>
          <ScaleCollapsible>
            <span slot="heading">{row.title}</span>
            <div style={{ display: 'flex' }}>
              {row.panels.map((p) => {
                if (notWorkingPanels.includes(p.title)) return null;
                return (
                  <ScaleCard key={p.title} label={p.title}>
                    <Typography variant="h6">{p.title}</Typography>
                    <MonitoringComponents key={p.title} panelInfo={p} dateRange={dateRange} />
                  </ScaleCard>
                );
              })}
            </div>
          </ScaleCollapsible>
        </ScaleAccordion>
      ))}
    </>
  ) : null;
};
export default Monitoring;
