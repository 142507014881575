import { ofType, combineEpics } from 'redux-observable';
import { mergeMap, catchError } from 'rxjs/operators';
import { from, of } from 'rxjs';

import Provision from '../../api/provision';
import { requestFailed } from '../status/actions';

import {
  FETCH_VIRTUAL_CLOUDS_STARTED,
  fetchVirtualCloudsFullfilled,
} from './actions';

const fetchVirtualCloudsEpic = (action$) => action$.pipe(
  ofType(FETCH_VIRTUAL_CLOUDS_STARTED),
  mergeMap((action) => from(Provision.fetchVirtualClouds(action.payload)).pipe(
    mergeMap((response) => of(
      fetchVirtualCloudsFullfilled(response.data),
    )),
    catchError(() => of(
      requestFailed(),
    )),
  )),
);

export default combineEpics(
  fetchVirtualCloudsEpic,
);
