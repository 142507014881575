export const FETCH_USERS_STARTED = 'FETCH_USERS_STARTED';
export const FETCH_USERS_FULLFILLED = 'FETCH_USERS_FULLFILLED';
export const CREATE_USER_STARTED = 'CREATE_USER_STARTED';
export const CREATE_USER_FULLFILLED = 'CREATE_USER_FULLFILLED';
export const UPDATE_USER_STARTED = 'UPDATE_USER_STARTED';
export const UPDATE_USER_FULLFILLED = 'UPDATE_USER_FULLFILLED';
export const DELETE_USER_STARTED = 'DELETE_USER_STARTED';
export const DELETE_USER_FULLFILLED = 'DELETE_USER_FULLFILLED';
export const ASSIGN_PRODUCT_STARTED = 'ASSIGN_PRODUCT_STARTED';
export const ASSIGN_PRODUCT_FULLFILLED = 'ASSIGN_PRODUCT_FULLFILLED';
export const UNASSIGN_PRODUCT_STARTED = 'UNASSIGN_PRODUCT_STARTED';
export const UNASSIGN_PRODUCT_FULLFILLED = 'UNASSIGN_PRODUCT_FULLFILLED';
export const fetchUsers = (data) => (
  { type: FETCH_USERS_STARTED, payload: data }
);
export const fetchUsersFullfilled = (data) => (
  { type: FETCH_USERS_FULLFILLED, payload: data }
);
export const createUser = (user) => (
  { type: CREATE_USER_STARTED, payload: user }
);
export const createUserFullfilled = (data) => (
  { type: CREATE_USER_FULLFILLED, payload: data }
);
export const updateUser = (payload) => (
  { type: UPDATE_USER_STARTED, payload }
);
export const updateUserFullfilled = (data) => (
  { type: UPDATE_USER_FULLFILLED, payload: data }
);
export const deleteUser = (payload) => (
  { type: DELETE_USER_STARTED, payload }
);
export const deleteUserFullfilled = (data) => (
  { type: DELETE_USER_FULLFILLED, payload: data }
);
export const assignProduct = (data) => (
  { type: ASSIGN_PRODUCT_STARTED, payload: data }
);
export const assignProductFullfilled = (data) => (
  { type: ASSIGN_PRODUCT_FULLFILLED, payload: data }
);
export const unassignProduct = (data) => (
  { type: UNASSIGN_PRODUCT_STARTED, payload: data }
);
export const unassignProductFullfilled = (data) => (
  { type: UNASSIGN_PRODUCT_FULLFILLED, payload: data }
);
