import PropTypes from 'prop-types';
import { ScaleButton, ScaleIconActionCopyPaste } from '@telekom/scale-components-react';
import { useDispatch } from 'react-redux';

import { enqueueSnackbar } from '../../redux/notifier/actions';

const CopyToClipboard = ({ data, onSubmit }) => {
  const dispatch = useDispatch();

  const copy = async () => {
    try {
      await navigator.clipboard.writeText(data);
      dispatch(enqueueSnackbar({
        message: 'copied',
        options: { variant: 'success' },
      }));
    } catch (error) {
      dispatch(enqueueSnackbar({
        message: 'copyFailed',
        options: { variant: 'error' },
      }));
    }
  };

  return (
    <>
      <pre style={{ textWrap: 'wrap', fontFamily: 'TeleNeoWeb' }}>{data}</pre>
      <ScaleButton
        slot="action"
        onClick={() => {
          copy();
          onSubmit();
        }}
      >
        Copy
        <ScaleIconActionCopyPaste />
      </ScaleButton>
    </>
  );
};

CopyToClipboard.propTypes = {
  data: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
};

CopyToClipboard.defaultProps = {
  data: '',
};

export default CopyToClipboard;
