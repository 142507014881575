import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ScaleModal } from '@telekom/scale-components-react';
import { Breadcrumbs, Grid, Typography } from '@mui/material';

import { assignableRoles, userRolesLookup } from '../../../helpers/lookups';
import OverviewTable from '../../../components/OverviewTable/OverviewTable';
import rbacCheck from '../../../rbac/rbacCheck';
import EditUserForm from './EditUserForm';
import DeleteUserForm from './DeleteUserForm';
import useApiCall from '../../../hooks/useApiCall';
import Auth from '../../../api/auth';
import { filterDataUpdates } from '../../../helpers/objects';


const UserDetails = () => {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);

  const [editModalOpened, setEditModalOpened] = useState(false);
  const [deleteModalOpened, setDeleteModalOpened] = useState(false);

  const getUserDetailsCall = useApiCall(Auth.fetchV2UserByUuid);
  const getUserCall = useApiCall(Auth.fetchV2Users);
  const updateUserCall = useApiCall(Auth.updateV2User);
  const deleteUserCall = useApiCall(Auth.deleteV2User);

  const [userDetails, setUserDetails] = useState({});
  const [createdBy, setCreatedBy] = useState({});

  const canEditRole = rbacCheck(user.roles, '/users:PUT', {
    userToBeUpdatedRole: userDetails.role,
    userToBeUpdatedEmail: userDetails.email,
    userEmail: user.email,
  });
  const canEditOrg = rbacCheck(user.roles, '/users:PUT:ORG');
  const canDeleteUser = rbacCheck(user.roles, '/users:DELETE', {
    userToBeDeletedRole: userDetails.role,
    userToBeDeletedEmail: userDetails.email,
    userEmail: user.email,
  });

  const userRoles = rbacCheck(user.roles, '/users:PUT_ROLE_CHECK');

  const getUserDetails = async () => {
    const [data] = await getUserDetailsCall(uuid);
    if (data) setUserDetails(data);
  };

  const getCreatedBy = async () => {
    const [data] = await getUserCall({ email: userDetails.createdByEmail });
    if (data) setCreatedBy(data[0]);
  };

  const updateUser = async (formValues) => {
    const updatedUser = filterDataUpdates(userDetails, formValues);
    if (updatedUser.roles) updatedUser.roles = updatedUser.roles.split();
    const [data, error] = await updateUserCall({
      ...updatedUser,
      uuid: userDetails.uuid,
    });
    if (error) setEditModalOpened(false);
    if (data) {
      getUserDetails();
      setEditModalOpened(false);
    }
  };

  const deleteUser = async () => {
    const [data] = await deleteUserCall(userDetails.uuid);
    if (data) navigate(-1);
  };

  useEffect(() => {
    getUserDetails();
  }, [uuid]);

  useEffect(() => {
    if (userDetails) getCreatedBy();
  }, [userDetails]);

  const rows = [
    { title: 'Role', value: userRolesLookup[userDetails.role] },
    {
      title: 'Organization',
      component: (
        <Link to={`/organizations/${userDetails.organizationId}`}>
          {userDetails.organization?.enterpriseName}
        </Link>
      ),
    },
    { title: 'Active', value: userDetails.activated ? 'True' : 'False' },
    {
      title: 'Created by',
      component: createdBy ? (
        <Link to={`/users/${createdBy.uuid}`}>
          {createdBy.email}
        </Link>
      ) : (
        userDetails.createdByEmail
      ),
    },
  ];


  return (
    <>
      { editModalOpened && (
        <ScaleModal
          opened
          size="small"
          heading="User Edit"
          onScale-close={() => setEditModalOpened(false)}
        >
          <EditUserForm
            roles={assignableRoles(userRoles)}
            user={userDetails}
            canEditOrg={canEditOrg}
            onCancel={() => setEditModalOpened(false)}
            onSubmit={updateUser}
          />
        </ScaleModal>
      )}
      { deleteModalOpened && (
        <ScaleModal
          opened
          size="small"
          heading="Delete User"
          onScale-close={() => setDeleteModalOpened(false)}
        >
          <DeleteUserForm
            user={userDetails}
            onSubmit={() => {
              deleteUser();
              setDeleteModalOpened(false);
            }}
            onCancel={() => setDeleteModalOpened(false)}
          />
        </ScaleModal>
      )}
      <Grid
        container
        direction="column"
        alignItems="stretch"
      >
        <Grid item alignSelf="flex-start">
          <Breadcrumbs key={userDetails.uuid} separator=">">
            <Link to="/organizations">organizations</Link>
            <Link to={`/organizations/${userDetails.organizationId}`}>{userDetails.organization?.enterpriseName}</Link>
            <Link to={`/organizations/${userDetails.organizationId}/users`}>users</Link>
            <Typography>{userDetails.email}</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <Grid
            container
            direction="column"
            alignItems="stretch"
            padding="2rem 10rem"
          >
            <Grid item>
              <OverviewTable
                header={userDetails.email}
                rows={rows}
                editAction={{
                  onClick: () => setEditModalOpened(true),
                  disabled: !canEditRole,
                  tooltip: 'userEditPermissionErr',
                }}
                deleteAction={{
                  onClick: () => setDeleteModalOpened(true),
                  disabled: !canDeleteUser,
                  tooltip: 'userDeletePermissionErr',
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default UserDetails;
