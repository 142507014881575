import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {
  Formik,
  Form,
  useFormikContext,
  useField,
} from 'formik';
import {
  ScaleButton,
  ScaleTextField,
  ScaleDropdownSelect,
  ScaleDropdownSelectItem,
} from '@telekom/scale-components-react';

import validator from 'validator';

import Provision from '../../api/provision';
import useApiCall from '../../hooks/useApiCall';


const initialValues = {
  email: '',
  type: '',
  name: '',
  phoneNumber: '',
  slackChannel: '',
};


const EmailField = () => {
  const { setFieldTouched } = useFormikContext();
  const [field, meta] = useField({ name: 'email' });
  return (
    <div className="form-field">
      <ScaleTextField
        {...field}
        label="Email"
        helperText={meta.touched ? meta.error : null}
        onScale-change={(e) => {
          field.onChange(e);
          setFieldTouched('email');
        }}
      />
    </div>
  );
};

const RoleField = () => {
  const roles = { stakeHolder: 'StakeHolder', serviceOwner: 'Service Owner', tenantOwner: 'Tenant Owner' };
  const { setFieldTouched } = useFormikContext();
  const [field] = useField({ name: 'type' });

  return (
    <div className="form-field">
      <ScaleDropdownSelect
        {...field}
        floatingStrategy="fixed"
        label="Role"
        onScale-change={(e) => {
          field.onChange(e);
          setFieldTouched('type');
        }}
      >
        {Object.keys(roles).map(
          (key) => (
            <ScaleDropdownSelectItem key={key} value={key}>
              {roles[key]}
            </ScaleDropdownSelectItem>
          ),
        )}
      </ScaleDropdownSelect>
    </div>
  );
};

const NameField = () => {
  const { setFieldTouched } = useFormikContext();
  const [field, meta] = useField({ name: 'name' });
  return (
    <div className="form-field">
      <ScaleTextField
        {...field}
        label="Name"
        helperText={meta.touched ? meta.error : null}
        onScale-change={(e) => {
          field.onChange(e);
          setFieldTouched('name');
        }}
      />
    </div>
  );
};

const PhoneNumberField = () => {
  const { setFieldTouched } = useFormikContext();
  const [field, meta] = useField({ name: 'phoneNumber' });

  return (
    <div className="form-field">
      <ScaleTextField
        {...field}
        label="Phone Number"
        helperText={meta.touched ? meta.error : null}
        onScale-change={(e) => {
          field.onChange(e);
          setFieldTouched('phoneNumber');
        }}
      />
    </div>
  );
};

const SlackChannelField = () => {
  const { setFieldTouched } = useFormikContext();
  const [field, meta] = useField({ name: 'slackChannel' });
  return (
    <div className="form-field">
      <ScaleTextField
        {...field}
        label="Slack Channel"
        helperText={meta.touched ? meta.error : null}
        onScale-change={(e) => {
          field.onChange(e);
          setFieldTouched('slackChannel');
        }}
      />
    </div>
  );
};


const CreateContactForm = ({
  formRef, juiceGroup, onSubmit, onCancel,
}) => {
  const addContact = useApiCall(Provision.addContact, 'ContactAdded', null);

  const validationSchema = Yup.object().shape({
    email: Yup.string().required('Required').email('Invalid email address'),
    type: Yup.string().required('Required'),
  });

  const submit = async (formValues) => {
    const [data] = await addContact({ ...formValues, juiceGroup });
    onSubmit(data.contacts);
    formRef.current.resetForm();
  };

  const validatePhoneNumber = (values) => {
    const errors = {};
    if (values.phoneNumber && !validator.isMobilePhone(values.phoneNumber, 'any', { strictMode: true })) {
      errors.phoneNumber = 'Invalid phone number';
    }

    return errors;
  };


  return (
    <Formik
      innerRef={formRef}
      validateOnChange
      initialValues={initialValues}
      validationSchema={validationSchema}
      validate={validatePhoneNumber}
      onSubmit={submit}
    >
      {({ dirty, isValid }) => (
        <Form>
          <EmailField />
          <RoleField />
          <NameField />
          <PhoneNumberField />
          <SlackChannelField />
          <div className="form-buttons">
            <ScaleButton
              variant="primary"
              disabled={!dirty || !isValid}
            >
              Create
            </ScaleButton>
            <ScaleButton
              name="cancel"
              type="button"
              variant="secondary"
              style={{ marginLeft: '1em' }}
              onClick={() => {
                formRef.current.resetForm();
                onCancel();
              }}
            >
              Cancel
            </ScaleButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};

CreateContactForm.propTypes = {
  formRef: PropTypes.shape({
    current: PropTypes.shape({ resetForm: PropTypes.func.isRequired }),
  }).isRequired,
  juiceGroup: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};


export default CreateContactForm;
