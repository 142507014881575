import { ofType, combineEpics } from 'redux-observable';
import { mergeMap, catchError } from 'rxjs/operators';
import { from, of } from 'rxjs';
import Auth from '../../../api/auth';
import {
  FETCH_PROJECTS_STARTED,
  fetchProjectsFullfilled,
  CREATE_PROJECT_STARTED,
  createProjectFullfilled,
  UPDATE_PROJECT_STARTED,
  updateProjectFullfilled,
  DELETE_PROJECT_STARTED,
  deleteProjectFullfilled,
} from './actions';
import { enqueueSnackbar } from '../../notifier/actions';
import { requestFailed } from '../../status/actions';

const fetchProjectsEpic = (action$) => action$.pipe(
  ofType(FETCH_PROJECTS_STARTED),
  mergeMap((action) => from(Auth.fetchV2Projects(action.payload)).pipe(
    mergeMap((response) => of(
      fetchProjectsFullfilled(response.data),
    )),
    catchError((error) => of(
      requestFailed(),
      enqueueSnackbar({
        message: error.message,
        options: { variant: 'error' },
      }),
    )),
  )),
);

const createProjectEpic = (action$) => action$.pipe(
  ofType(CREATE_PROJECT_STARTED),
  mergeMap((action) => from(Auth.createV2Project(action.payload)).pipe(
    mergeMap((response) => of(
      createProjectFullfilled(response.data),
      enqueueSnackbar({
        message: 'projectCreation',
        values: { name: response.data.name },
        options: { variant: 'success' },
      }),
    )),
    catchError((error) => of(
      requestFailed(),
      enqueueSnackbar({
        message: error.message,
        options: { variant: 'error' },
      }),
    )),
  )),
);

const updateProjectEpic = (action$) => action$.pipe(
  ofType(UPDATE_PROJECT_STARTED),
  mergeMap((action) => from(Auth.updateV2Project(action.payload)).pipe(
    mergeMap((response) => of(
      updateProjectFullfilled(response.data),
      enqueueSnackbar({
        message: 'projectEdit',
        values: { name: response.data.name },
        options: { variant: 'success' },
      }),
    )),
    catchError((error) => of(
      requestFailed(),
      enqueueSnackbar({
        message: error.message,
        options: { variant: 'error' },
      }),
    )),
  )),
);

const deleteProjectEpic = (action$) => action$.pipe(
  ofType(DELETE_PROJECT_STARTED),
  mergeMap((action) => from(Auth.deleteV2Project(action.payload)).pipe(
    mergeMap((response) => of(
      deleteProjectFullfilled(response.data),
      enqueueSnackbar({
        message: 'projectDeleted',
        values: { name: response.data.name },
        options: { variant: 'success' },
      }),
    )),
    catchError((error) => of(
      requestFailed(),
      enqueueSnackbar({
        message: error.message,
        options: { variant: 'error' },
      }),
    )),
  )),
);

export default combineEpics(
  fetchProjectsEpic,
  createProjectEpic,
  updateProjectEpic,
  deleteProjectEpic,
);
