import {
  CREATE_SAFESCARF_PRODUCT_FULLFILLED,
  FETCH_SAFESCARF_PRODUCTS_FULLFILLED,
  UPDATE_SAFESCARF_PRODUCT_FULLFILLED,
  DELETE_SAFESCARF_PRODUCT_FULLFILLED,
} from './actions';


const productsReducer = (state = [], action) => {
  switch (action.type) {
    case CREATE_SAFESCARF_PRODUCT_FULLFILLED:
      return [...state, action.payload];
    case FETCH_SAFESCARF_PRODUCTS_FULLFILLED:
      return action.payload;
    case UPDATE_SAFESCARF_PRODUCT_FULLFILLED:
      return state.map((product) => {
        if (product.id === action.payload.id) {
          return action.payload;
        }
        return product;
      });
    case DELETE_SAFESCARF_PRODUCT_FULLFILLED:
      return state.filter((product) => product.id.toString() !== action.payload);
    default:
      return state;
  }
};

export { productsReducer };

