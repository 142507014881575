import { FETCH_MARKETPLACE_ITEMS_FULLFILLED } from './actions';

const marketplaceItemsAdminReducer = (state = [], action) => {
  switch (action.type) {
    case FETCH_MARKETPLACE_ITEMS_FULLFILLED:
      return action.payload;
    default:
      return state;
  }
};

export { marketplaceItemsAdminReducer };
