import { ofType, combineEpics } from 'redux-observable';
import { mergeMap, catchError } from 'rxjs/operators';
import { from, of } from 'rxjs';

import Safescarf from '../../../api/safescarf';

import {
  CREATE_PRODUCT_MEMBER_STARTED,
  createProductMemberFullfilled,
  FETCH_PRODUCT_MEMBERS_STARTED,
  fetchProductMembersFullfilled,
  UPDATE_PRODUCT_MEMBER_STARTED,
  updateProductMemberFullfilled,
  DELETE_PRODUCT_MEMBER_STARTED,
  deleteProductMemberFullfilled,
} from './actions';
import { enqueueSnackbar } from '../../notifier/actions';
import { requestFailed } from '../../status/actions';


const createProductMemberEpic = (action$) => action$.pipe(
  ofType(CREATE_PRODUCT_MEMBER_STARTED),
  mergeMap((action) => from(Safescarf.createSafescarfProductMember(action.payload)).pipe(
    mergeMap((response) => of(
      createProductMemberFullfilled(response.data),
    )),
    catchError((error) => of(
      requestFailed(),
      enqueueSnackbar({
        message: error.message,
        options: { variant: 'error' },
      }),
    )),
  )),
);
const fetchProductMembersEpic = (action$) => action$.pipe(
  ofType(FETCH_PRODUCT_MEMBERS_STARTED),
  mergeMap((action) => from(Safescarf.fetchSafescarfProductMembers(action.payload)).pipe(
    mergeMap((response) => of(
      fetchProductMembersFullfilled(response.data),
    )),
    catchError((error) => of(
      requestFailed(),
      enqueueSnackbar({
        message: error.message,
        options: { variant: 'error' },
      }),
    )),
  )),
);
const updateProductMemberEpic = (action$) => action$.pipe(
  ofType(UPDATE_PRODUCT_MEMBER_STARTED),
  mergeMap((action) => from(Safescarf.updateSafescarfProductMember(action.payload)).pipe(
    mergeMap((response) => of(
      updateProductMemberFullfilled(response.data),
    )),
    catchError((error) => of(
      requestFailed(),
      enqueueSnackbar({
        message: error.message,
        options: { variant: 'error' },
      }),
    )),
  )),
);
const deleteProductMemberEpic = (action$) => action$.pipe(
  ofType(DELETE_PRODUCT_MEMBER_STARTED),
  mergeMap((action) => from(Safescarf.deleteSafescarfProductMember(action.payload)).pipe(
    mergeMap(() => of(
      deleteProductMemberFullfilled(action.payload),
    )),
    catchError((error) => of(
      requestFailed(),
      enqueueSnackbar({
        message: error.message,
        options: { variant: 'error' },
      }),
    )),
  )),
);

export default combineEpics(
  createProductMemberEpic,
  fetchProductMembersEpic,
  updateProductMemberEpic,
  deleteProductMemberEpic,
);
