import { Typography } from '@mui/material';

const SomethingWentWrong = () => (
  <div style={{ padding: '10rem 0' }}>
    <Typography
      variant="h2"
      color="primary"
      gutterBottom
      style={{ display: 'flex', justifyContent: 'center' }}
    >
      Apologies.
    </Typography>
    <Typography variant="h6" style={{ display: 'flex', justifyContent: 'center' }}>
      Something went wrong.
    </Typography>
    <Typography variant="h6" paragraph style={{ display: 'flex', justifyContent: 'center' }}>
      We are working on it.
    </Typography>
  </div>
);

export default SomethingWentWrong;
