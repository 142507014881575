export const FETCH_VIRTUAL_CLOUDS_STARTED = 'FETCH_VIRTUAL_CLOUDS_STARTED';
export const FETCH_VIRTUAL_CLOUDS_FULLFILLED = 'FETCH_VIRTUAL_CLOUDS_FULLFILLED';
export const CREATE_VIRTUAL_CLOUDS_STARTED = 'CREATE_VIRTUAL_CLOUD_STARTED';
export const CREATE_VIRTUAL_CLOUDS_FULLFILLED = 'CREATE_VIRTUAL_CLOUD_FULLFILLED';
export const fetchVirtualClouds = (data) => (
  { type: FETCH_VIRTUAL_CLOUDS_STARTED, payload: data }
);
export const fetchVirtualCloudsFullfilled = (data) => (
  { type: FETCH_VIRTUAL_CLOUDS_FULLFILLED, payload: data }
);
