import { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { ScaleModal } from '@telekom/scale-components-react';
import { useIntl } from 'react-intl';
import Provision from '../../api/provision';

import CreateContactForm from './CreateContactForm';
import Table from '../../components/Table';

import useApiCall from '../../hooks/useApiCall';
import rbacCheck from '../../rbac/rbacCheck';

const lookupType = {
  stakeHolder: 'Stakeholder',
  serviceOwner: 'Service Owner',
  tenantOwner: 'Tenant Owner',
};

const contactFields = [
  { id: 'email', title: 'Email' },
  { id: 'role', title: 'Role' },
  { id: 'name', title: 'Name' },
  { id: 'phone', title: 'Phone' },
  { id: 'slack', title: 'Slack' },
];

const JuiceGroupContacts = ({ juiceGroup: propJuiceGroup }) => {
  const formRef = useRef();
  const intl = useIntl();
  const navigate = useNavigate();
  const getJuicegroupInfo = useApiCall(Provision.fetchJuiceGroupInfo, null, null);
  const roles = useSelector((state) => state.user);

  const { juiceGroup: paramJuiceGroup } = useParams();
  const juiceGroup = propJuiceGroup || paramJuiceGroup;
  const [juiceGroupInfoContacts, setJuiceGroupInfoContacts] = useState([]);
  const [addContactModalOpened, setAddContactModalOpened] = useState(false);

  const canAddEditContacts = rbacCheck(roles, '/products:CONTACTS_PATCH');

  useEffect(() => {
    async function getContacts() {
      const [data] = await getJuicegroupInfo({ juiceGroup });
      setJuiceGroupInfoContacts(data.contacts);
    }
    getContacts();
  }, []);

  const mapToRows = (contacts) => (
    contacts?.map((contact) => ({
      key: contact._id,
      onRowClick: () => (paramJuiceGroup ? navigate(`contacts/${contact._id}`) : navigate(`contacts/${propJuiceGroup}/${contact._id}`)),
      data: [
        { field: 'email', value: contact.email },
        { field: 'role', value: lookupType[contact.type] },
        { field: 'name', value: contact.name || '' },
        { field: 'phone', value: contact.phoneNumber || '' },
        { field: 'slack', value: contact.slackChannel || '' },
      ],
    }
    ))
  );

  const emailFile = new Blob([juiceGroupInfoContacts?.map((contact) => contact.email).join(';')], { type: 'text/plain' });

  return (
    <>
      <ScaleModal
        heading="Add Contact Info"
        opened={addContactModalOpened}
        size="small"
        onScale-close={() => {
          formRef.current.resetForm();
          setAddContactModalOpened(false);
        }}
      >
        <CreateContactForm
          formRef={formRef}
          juiceGroup={juiceGroup}
          onSubmit={(data) => {
            setJuiceGroupInfoContacts(data);
            setAddContactModalOpened(false);
          }}
          onCancel={() => setAddContactModalOpened(false)}
        />
      </ScaleModal>
      <Table
        header="Contacts"
        fields={contactFields}
        rows={juiceGroupInfoContacts ? mapToRows(juiceGroupInfoContacts) : []}
        createAction={{
          disabled: !canAddEditContacts,
          onClick: () => setAddContactModalOpened(true),
          tooltip: !canAddEditContacts ? intl.formatMessage({ id: 'contactCreateDisabled' }) : '',
        }}
        exportData={paramJuiceGroup ? {
          name: 'Export Emails',
          fileName: `${paramJuiceGroup}_emails.txt`,
          file: emailFile,
        } : null}
      />
    </>
  );
};

JuiceGroupContacts.defaultProps = {
  juiceGroup: null,
};

JuiceGroupContacts.propTypes = {
  juiceGroup: PropTypes.string,
};

export default JuiceGroupContacts;
