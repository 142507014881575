import { ofType, combineEpics } from 'redux-observable';
import { mergeMap, catchError } from 'rxjs/operators';
import { from, of } from 'rxjs';

import Safescarf from '../../../api/safescarf';

import {
  CREATE_PRODUCT_TYPE_STARTED,
  createProductTypeFullfilled,
  FETCH_PRODUCT_TYPES_STARTED,
  fetchProductTypesFullfilled,
  UPDATE_PRODUCT_TYPE_STARTED,
  updateProductTypeFullfilled,

} from './actions';
import { fetchProductTypeMembers } from '../productTypeMembers/actions';
import { enqueueSnackbar } from '../../notifier/actions';
import { requestFailed } from '../../status/actions';
// PRODUCT_TYPES
const createProductTypeEpic = (action$) => action$.pipe(
  ofType(CREATE_PRODUCT_TYPE_STARTED),
  mergeMap((action) => from(Safescarf.createSafescarfProductType(action.payload)).pipe(
    mergeMap((response) => of(
      createProductTypeFullfilled(response.data),
      fetchProductTypeMembers(),
    )),
    catchError((error) => of(
      requestFailed(),
      enqueueSnackbar({
        message: error.message,
        options: { variant: 'error' },
      }),
    )),
  )),
);
const fetchProductTypesEpic = (action$) => action$.pipe(
  ofType(FETCH_PRODUCT_TYPES_STARTED),
  mergeMap(() => from(Safescarf.fetchSafescarfProductTypes()).pipe(
    mergeMap((response) => of(
      fetchProductTypesFullfilled(response.data),
    )),
    catchError((error) => of(
      requestFailed(),
      enqueueSnackbar({
        message: error.message,
        options: { variant: 'error' },
      }),
    )),
  )),
);
const updateProductTypeEpic = (action$) => action$.pipe(
  ofType(UPDATE_PRODUCT_TYPE_STARTED),
  mergeMap((action) => from(Safescarf.updateSafescarfProductType(action.payload)).pipe(
    mergeMap((response) => of(
      updateProductTypeFullfilled(response.data),
    )),
    catchError((error) => of(
      requestFailed(),
      enqueueSnackbar({
        message: error.message,
        options: { variant: 'error' },
      }),
    )),
  )),
);

export default combineEpics(
  createProductTypeEpic,
  fetchProductTypesEpic,
  updateProductTypeEpic,
);
