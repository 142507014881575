import { Grid, Typography } from '@mui/material';

const CaaSonOsprojectSpecification = () => (
  <Grid container padding="2rem 10rem">
    <Grid item>
      <Typography variant="h4" color="primary">CaaSonOsproject specification: Coming soon</Typography>
    </Grid>
  </Grid>
);

export default CaaSonOsprojectSpecification;
