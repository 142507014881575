export const FETCH_PRODUCT_TYPES_STARTED = 'FETCH_PRODUCT_TYPES_STARTED';
export const FETCH_PRODUCT_TYPES_FULLFILLED = 'FETCH_PRODUCT_TYPES_FULLFILLED';
export const UPDATE_PRODUCT_TYPE_STARTED = 'UPDATE_PRODUCT_TYPE_STARTED';
export const UPDATE_PRODUCT_TYPE_FULLFILLED = 'UPDATE_PRODUCT_TYPE_FULLFILLED';
export const CREATE_PRODUCT_TYPE_STARTED = 'CREATE_PRODUCT_TYPE_STARTED';
export const CREATE_PRODUCT_TYPE_FULLFILLED = 'CREATE_PRODUCT_TYPE_FULLFILLED';

export const fetchProductTypes = () => (
  { type: FETCH_PRODUCT_TYPES_STARTED }
);

export const fetchProductTypesFullfilled = (data) => (
  { type: FETCH_PRODUCT_TYPES_FULLFILLED, payload: data }
);

export const updateProductType = (data) => (
  { type: UPDATE_PRODUCT_TYPE_STARTED, payload: data }
);

export const updateProductTypeFullfilled = (data) => (
  { type: UPDATE_PRODUCT_TYPE_FULLFILLED, payload: data }
);

export const createProductType = (data) => (
  { type: CREATE_PRODUCT_TYPE_STARTED, payload: data }
);

export const createProductTypeFullfilled = (data) => (
  { type: CREATE_PRODUCT_TYPE_FULLFILLED, payload: data }
);
