import { Container } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ScaleButton, ScaleIconActionCopyPaste, ScaleModal } from '@telekom/scale-components-react';
import { v4 as uuidv4 } from 'uuid';
import OverviewTable from '../../../components/OverviewTable/OverviewTable';
import { requestFailed, requestFullfilled, requestStarted } from '../../../redux/status/actions';
import Caas from '../../../api/caas';

import CopyToClipboard from '../../../components/CopyToClipboard';
import { enqueueSnackbar } from '../../../redux/notifier/actions';


const CaasCluster = () => {
  const { clusterName } = useParams();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);

  const [cluster, setCluster] = useState({});
  const [kubeconfig, setKubeconfig] = useState();
  const [kubeconfigModalOpened, setKubeconfigModalOpened] = useState(false);

  const getClusterDetails = async () => {
    try {
      dispatch(requestStarted());
      const clusterResponse = await Caas.getClusterDetails(uuidv4());
      setCluster(clusterResponse.data);
      dispatch(requestFullfilled());
    } catch (error) {
      dispatch(requestFailed());
    }
  };

  const getKubeConfig = async () => {
    try {
      dispatch(requestStarted());
      const kubeconfigResponse = await Caas.getClusterKubeConfig(uuidv4());
      setKubeconfig(kubeconfigResponse.data.value);
      dispatch(requestFullfilled());
      setKubeconfigModalOpened(true);
    } catch (error) {
      dispatch(requestFailed());
      dispatch(enqueueSnackbar({
        message: 'CaasClusterKubeconfigGetErr',
        options: { variant: 'error' },
      }));
    }
  };

  useEffect(() => {
    getClusterDetails();
  }, [clusterName]);

  return cluster?.name ? (
    <Container maxWidth="md">
      <ScaleModal
        opened={kubeconfigModalOpened}
        heading="Kube Config"
        onScale-close={() => setKubeconfigModalOpened(false)}
      >
        <CopyToClipboard
          data={kubeconfig}
          onSubmit={() => { setKubeconfigModalOpened(false); }}
        />
      </ScaleModal>
      <OverviewTable
        header={`${cluster.name} Cluster Details`}
        rows={[
          {
            title: 'Owner',
            value: cluster.owner.email,
          },
          {
            title: 'Cloud',
            value: cluster.cloud,
          },
          {
            title: 'Environmnet',
            value: cluster.environment,
          },
          {
            title: 'Kubernetes Version',
            value: cluster.kubernetesVersion,
          },
          {
            title: 'Kube Config',
            component: (
              <ScaleButton
                variant="secondary"
                size="small"
                disabled={cluster?.owner.email !== user.email}
                onClick={getKubeConfig}
              >
                <ScaleIconActionCopyPaste />
                Copy Kube Config
              </ScaleButton>
            ),
          },
          {
            title: 'Groups',
            value: Object.keys(cluster.workerGroups).length,
          },
          {
            title: 'Status',
            value: cluster.status.operationStatus,
          },
        ]}
      />
    </Container>
  ) : null;
};

export default CaasCluster;
