import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {
  Formik,
  Form,
} from 'formik';
import { ScaleButton } from '@telekom/scale-components-react';
import { Grid } from '@mui/material';
import useApiCall from '../../../hooks/useApiCall';
import Auth from '../../../api/auth';
import { FormikSelect, FormikTextField } from '../../../components/Formik/index';

const EditBusinessUnitForm = ({
  businessUnit,
  onSubmit,
  onCancel,
}) => {
  const getUsersCall = useApiCall(Auth.fetchV2Users);
  const [businessUnitManagers, setBusinessUnitManagers] = useState([]);

  useEffect(() => {
    async function getBuManagers() {
      const [data] = await getUsersCall({
        organizationId: businessUnit.organizationId,
        roles: 'BUSINESS_UNIT_MANAGER',
      });
      if (data) setBusinessUnitManagers(data);
    }
    getBuManagers();
  }, []);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    description: Yup.string().required('Required'),
  });

  const initialValues = {
    name: businessUnit?.name || '',
    description: businessUnit?.description || '',
    businessUnitManager: businessUnit?.businessUnitManager?._id || '',
  };

  const businessUnitManagersItems = businessUnitManagers.map((manager) => ({
    value: manager._id,
    label: manager.email,
  }));

  return (
    <Formik
      enableReinitialize
      validateOnChange
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ dirty, isValid }) => (
        <Form>
          <Grid
            container
            justifyContent="end"
            gap={2}
            py={2}
          >
            <FormikTextField
              name="name"
              label="Name"
            />
            <FormikTextField
              name="description"
              label="Description"
            />
            {businessUnitManagers && (
              <FormikSelect
                name="businessUnitManager"
                label="Business Unit Manager"
                menuItems={businessUnitManagersItems}
              />
            )}
            <ScaleButton
              variant="primary"
              disabled={!dirty || !isValid}
            >
              Save
            </ScaleButton>
            <ScaleButton
              type="button"
              variant="secondary"
              onClick={onCancel}
            >
              Cancel
            </ScaleButton>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

EditBusinessUnitForm.propTypes = {
  businessUnit: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    organizationId: PropTypes.string.isRequired,
    businessUnitManager: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
    }),
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default EditBusinessUnitForm;
