export const FETCH_ROLES_STARTED = 'FETCH_ROLES_STARTED';
export const FETCH_ROLES_FULLFILLED = 'FETCH_ROLES_FULLFILLED';

export const fetchRoles = () => (
  { type: FETCH_ROLES_STARTED }
);

export const fetchRolesFullfilled = (data) => (
  { type: FETCH_ROLES_FULLFILLED, payload: data }
);
