export const FETCH_MACHINE_ACCOUNTS_STARTED = 'FETCH_MACHINE_ACCOUNTS_STARTED';
export const FETCH_MACHINE_ACCOUNTS_FULLFILLED = 'FETCH_MACHINE_ACCOUNTS_FULLFILLED';
export const CREATE_MACHINE_ACCOUNT_STARTED = 'CREATE_MACHINE_ACCOUNT_STARTED';
export const CREATE_MACHINE_ACCOUNT_FULLFILLED = 'CREATE_MACHINE_ACCOUNT_FULLFILLED';
export const REMOVE_MACHINE_ACCOUNT_TOKENS = 'REMOVE_MACHINE_ACCOUNT_TOKENS';

export const fetchMachineAccounts = () => (
  { type: FETCH_MACHINE_ACCOUNTS_STARTED }
);

export const fetchMachineAccountsFullfilled = (data) => (
  { type: FETCH_MACHINE_ACCOUNTS_FULLFILLED, payload: data }
);

export const createMachineAccount = () => (
  { type: CREATE_MACHINE_ACCOUNT_STARTED }
);

export const createMachineAccountFullfilled = (data) => (
  { type: CREATE_MACHINE_ACCOUNT_FULLFILLED, payload: data }
);

export const removeMachineAccountTokens = () => (
  { type: REMOVE_MACHINE_ACCOUNT_TOKENS }
);
