import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { BarChart } from '@mui/x-charts';

import MonitoringMs from '../../../../api/monitoring';
import useApiCall from '../../../../hooks/useApiCall';

const BarChartComponent = ({ panelInfo, dateRange }) => {
  const [values, setValues] = useState({ xAxis: [], series: [] });
  const getPanelDataApiCall = useApiCall(MonitoringMs.getPanel);
  const getPanel = async (targets) => {
    const [data] = await getPanelDataApiCall(targets);
    if (data) {
      const refIds = panelInfo.targets.filter((target) => !target.hide)
        .map((target) => target.refId);
      const panelData = refIds.map((refId) => data.results[refId].frames[0]);
      setValues({
        xAxis: [{ data: panelData[0].data.values[0], scaleType: 'band' }],
        series: panelData.map((frame) => ({
          data: frame.data.values[1], stack: 'A', label: frame.schema.fields[1].name, scaleType: 'band',
        })),
      });
    }
  };

  useEffect(() => {
    const toDate = new Date(dateRange.to).getTime();
    const fromDate = new Date(dateRange.from).getTime();
    if (panelInfo.targets) {
      getPanel({ queries: panelInfo.targets, to: toDate.toString(), from: fromDate.toString() });
    }
  }, []);

  return (
    <BarChart
      series={values.series}
      height={340}
      width={400}
      colors={['#E20074', '#f399c7']}
      xAxis={values.xAxis}
      grid={{ horizontal: true }}
    />
  );
};

BarChartComponent.propTypes = {
  panelInfo: PropTypes.shape({
    title: PropTypes.string,
    targets: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  }).isRequired,
  dateRange: PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.string,
  }).isRequired,
};


export default BarChartComponent;
