export const FETCH_PRODUCTS_STARTED = 'FETCH_PRODUCTS_STARTED';
export const FETCH_PRODUCTS_FULLFILLED = 'FETCH_PRODUCTS_FULLFILLED';
export const CREATE_PRODUCT_STARTED = 'CREATE_PRODUCT_STARTED';
export const CREATE_PRODUCT_FULLFILLED = 'CREATE_PRODUCT_FULLFILLED';
export const UPDATE_PRODUCT_STARTED = 'UPDATE_PRODUCT_STARTED';
export const UPDATE_PRODUCT_FULLFILLED = 'UPDATE_PRODUCT_FULLFILLED';
export const DELETE_PRODUCT_STARTED = 'DELETE_PRODUCT_STARTED';
export const DELETE_PRODUCT_FULLFILLED = 'DELETE_PRODUCT_FULLFILLED';
export const fetchProducts = (data) => ({
  type: FETCH_PRODUCTS_STARTED, payload: data,
});
export const fetchProductsFullfilled = (data) => (
  { type: FETCH_PRODUCTS_FULLFILLED, payload: data }
);
export const createProduct = (product) => (
  { type: CREATE_PRODUCT_STARTED, payload: product }
);
export const createProductFullfilled = (data) => (
  { type: CREATE_PRODUCT_FULLFILLED, payload: data }
);
export const updateProduct = (product) => (
  { type: UPDATE_PRODUCT_STARTED, payload: product }
);
export const updateProductFullfilled = (data) => (
  { type: UPDATE_PRODUCT_FULLFILLED, payload: data }
);
export const deleteProduct = (product) => (
  { type: DELETE_PRODUCT_STARTED, payload: product }
);
export const deleteProductFullfilled = (data) => (
  { type: DELETE_PRODUCT_FULLFILLED, payload: data }
);
