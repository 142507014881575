export const FETCH_PRODUCT_TYPE_MEMBERS_STARTED = 'FETCH_PRODUCT_TYPE_MEMBERS_STARTED';
export const FETCH_PRODUCT_TYPE_MEMBERS_FULLFILLED = 'FETCH_PRODUCT_TYPE_MEMBERS_FULLFILLED';
export const CREATE_PRODUCT_TYPE_MEMBER_STARTED = 'CREATE_PRODUCT_TYPE_MEMBER_STARTED';
export const CREATE_PRODUCT_TYPE_MEMBER_FULLFILLED = 'CREATE_PRODUCT_TYPE_MEMBER_FULLFILLED';
export const UPDATE_PRODUCT_TYPE_MEMBER_STARTED = 'UPDATE_PRODUCT_TYPE_MEMBER_STARTED';
export const UPDATE_PRODUCT_TYPE_MEMBER_FULLFILLED = 'UPDATE_PRODUCT_TYPE_MEMBER_FULLFILLED';
export const DELETE_PRODUCT_TYPE_MEMBER_STARTED = 'DELETE_PRODUCT_TYPE_MEMBER_STARTED';
export const DELETE_PRODUCT_TYPE_MEMBER_FULLFILLED = 'DELETE_PRODUCT_TYPE_MEMBER_FULLFILLED';

export const fetchProductTypeMembers = (productTypeId) => (
  { type: FETCH_PRODUCT_TYPE_MEMBERS_STARTED, payload: productTypeId }
);

export const fetchProductTypeMembersFullfilled = (data) => (
  { type: FETCH_PRODUCT_TYPE_MEMBERS_FULLFILLED, payload: data }
);

export const createProductTypeMember = (data) => (
  { type: CREATE_PRODUCT_TYPE_MEMBER_STARTED, payload: data }
);

export const createProductTypeMemberFullfilled = (data) => (
  { type: CREATE_PRODUCT_TYPE_MEMBER_FULLFILLED, payload: data }
);

export const updateProductTypeMember = (data) => (
  { type: UPDATE_PRODUCT_TYPE_MEMBER_STARTED, payload: data }
);

export const updateProductTypeMemberFullfilled = (data) => (
  { type: UPDATE_PRODUCT_TYPE_MEMBER_FULLFILLED, payload: data }
);

export const deleteProductTypeMember = (memberId) => (
  { type: DELETE_PRODUCT_TYPE_MEMBER_STARTED, payload: memberId }
);

export const deleteProductTypeMemberFullfilled = (productTypeId) => (
  { type: DELETE_PRODUCT_TYPE_MEMBER_FULLFILLED, payload: productTypeId }
);
