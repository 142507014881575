import { useIntl } from 'react-intl';
import { Grid, Typography } from '@mui/material';

import { ScaleDivider } from '@telekom/scale-components-react';

import tdiLogo from './tdiLogo_resized.png';
import juiceLogo from './juice_logo_with_white_bg.png';
import safescarf from './safescarfLogo_resized.png';
import caas from './ComputeLogo_resized.png';


const Home = () => {
  const intl = useIntl();
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      paddingTop="3rem"
    >
      <Grid item>
        <Grid container spacing={2} justifyContent="center">
          <Grid item lg={10} xl={2}>
            <img src={tdiLogo} alt="tdi" />
          </Grid>
          <Grid item lg={10} xl={10}>
            <Typography color="primary" variant="h4">
              {intl.formatMessage({ id: 'title' })}
            </Typography>
            <Typography variant="h4">
              {intl.formatMessage({ id: 'iaasDescription' })}
            </Typography>
            <Typography variant="h5">
              {intl.formatMessage({ id: 'iaasSubDescription' })}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <ScaleDivider />
      <Grid item>
        <Typography color="primary" variant="h4" style={{ marginTop: '1rem' }}>{intl.formatMessage({ id: 'productsTitle' })}</Typography>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
        >
          <Grid item lg={8} xl={4}>
            <img src={juiceLogo} alt="juice" />
            <Typography variant="h5" align="center">
              {intl.formatMessage({ id: 'juiceTitle' })}
            </Typography>
            <Typography variant="h6" align="center" style={{ fontWeight: 'bold' }}>
              (JUICE)
            </Typography>
            <Typography>
              {intl.formatMessage({ id: 'juiceDescription' })}
            </Typography>
          </Grid>
          <Grid item lg={8} xl={4}>
            <img src={safescarf} alt="safescarf" />
            <Typography variant="h5" align="center">
              {intl.formatMessage({ id: 'safescarfTitle' })}
            </Typography>
            <Typography>
              {intl.formatMessage({ id: 'safescarfDescription' })}
            </Typography>
          </Grid>
          <Grid item lg={8} xl={4}>
            <img src={caas} alt="caas" />
            <Typography variant="h5" align="center">
              {intl.formatMessage({ id: 'computeTitle' })}
            </Typography>
            <Typography>
              {intl.formatMessage({ id: 'computeDescription' })}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Home;
