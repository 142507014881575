import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { ScaleModal } from '@telekom/scale-components-react';
import { Breadcrumbs, Grid, Typography } from '@mui/material';

import rbacCheck from '../../../../../rbac/rbacCheck';
import OverviewTable from '../../../../../components/OverviewTable/OverviewTable';
import EditProductTypeForm from './EditProductTypeForm';

import { fetchUsers as fetchSafescarfUsers } from '../../../../../redux/safescarf/users/actions';
import { fetchProductTypes } from '../../../../../redux/safescarf/productTypes/actions';
import ProductTypeMembers from './ProductTypeMembers';


const ProductTypeDetails = () => {
  const { productTypeId } = useParams();
  const dispatch = useDispatch();

  const [editModalOpened, setEditModalOpened] = useState(false);
  const [safescarfUser, setSafescarfUser] = useState({});

  const userData = useSelector((state) => state.user);
  const safescarfUsers = useSelector((state) => state.safescarf.users);
  const productTypes = useSelector((state) => state.safescarf.productTypes);

  const productType = productTypes.find((pt) => pt.id === Number(productTypeId));

  useEffect(() => {
    if (!safescarfUsers.length) dispatch(fetchSafescarfUsers());
    if (!productTypes.length) dispatch(fetchProductTypes());
  }, []);

  useEffect(() => {
    if (userData.email && safescarfUsers.length) {
      setSafescarfUser(safescarfUsers.find((user) => user.username === userData.email));
    }
  }, [userData, safescarfUsers]);

  const canEditProductType = safescarfUser && rbacCheck(
    userData.roles,
    '/products/safescarf/product-types:PUT',
    { productType, memberId: safescarfUser.id },
  );


  return (
    <>
      {editModalOpened && (
        <ScaleModal
          opened
          size="small"
          heading="Edit Product Type"
          onScale-close={() => setEditModalOpened(false)}
        >
          <EditProductTypeForm
            productType={productType}
            onCancel={() => setEditModalOpened(false)}
            onSubmit={() => setEditModalOpened(false)}
          />
        </ScaleModal>
      )}
      <Grid
        container
        direction="column"
        alignItems="stretch"
      >
        <Grid item alignSelf="flex-start">
          <Breadcrumbs key={productType?.name} separator=">">
            <Link to="/products/safescarf">Safescarf</Link>
            <Link to="/products/safescarf/product-types">product types</Link>
            <Typography>{productType?.name}</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <Grid
            container
            direction="column"
            padding="2rem 10rem"
            rowGap={8}
          >
            <Grid item>
              <OverviewTable
                header={productType?.name}
                rows={[
                  {
                    title: 'Description:',
                    component: productType?.description,
                  },
                ]}
                editAction={{
                  onClick: () => setEditModalOpened(true),
                  disabled: !canEditProductType,
                  tooltip: 'editProductTypeDisabled',
                }}
              />
            </Grid>
            <Grid item>
              <ProductTypeMembers />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ProductTypeDetails;
