import {
  CREATE_MACHINE_ACCOUNT_FULLFILLED,
  FETCH_MACHINE_ACCOUNTS_FULLFILLED,
  REMOVE_MACHINE_ACCOUNT_TOKENS,
} from './actions';

const machineAccountsReducer = (state = [], action) => {
  switch (action.type) {
    case CREATE_MACHINE_ACCOUNT_FULLFILLED:
      return [...state, action.payload];
    case FETCH_MACHINE_ACCOUNTS_FULLFILLED:
      return action.payload;
    case REMOVE_MACHINE_ACCOUNT_TOKENS:
      return state.map((ma) => {
        const { token, ...rest } = ma;
        return rest;
      });
    default:
      return state;
  }
};

export { machineAccountsReducer };

