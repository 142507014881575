import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ScaleDropdownSelectItem, ScaleDropdownSelect } from '@telekom/scale-components-react';

import Section from '../../../../../components/Section';
import ForecastSummary from './ForecastSummary';
import VirtualCloudsSummary from './VirtualCloudsSummary';
import UsageSummary from './UsageSummary';

import Provision from '../../../../../api/provision';
import useApiCall from '../../../../../hooks/useApiCall';

const Summaries = ({ juiceGroup }) => {
  const fetchVirtualClouds = useApiCall(Provision.fetchVirtualClouds);
  const fetchClouds = useApiCall(Provision.fetchClouds);
  const [virtualClouds, setVirtualClouds] = useState(undefined);
  const [clouds, setClouds] = useState(undefined);
  const [cloudId, setCloudId] = useState(undefined);
  const [juiceGroupClouds, setJuiceGroupClouds] = useState(undefined);

  useEffect(() => {
    const getClouds = async () => {
      const [data] = await fetchClouds();
      if (data) {
        setClouds(data);
      }
    };
    getClouds();
  }, []);

  useEffect(() => {
    const getVirtualClouds = async () => {
      const [data] = await fetchVirtualClouds({ juiceGroups: juiceGroup });
      if (data?.length > 0) {
        setJuiceGroupClouds(
          clouds.filter((cloud) => data.find((vc) => vc.cloud.name === cloud.id)),
        );
        setVirtualClouds(data);
      }
    };
    if (juiceGroup) getVirtualClouds();
  }, [clouds, juiceGroup]);


  return (
    <>
      <div style={{ marginBottom: '1rem' }}>
        <ScaleDropdownSelect
          controlled
          id="cloudDropdown"
          name="cloudDropdown"
          label="Select a cloud to see the available hypervisors"
          onScale-change={(event) => {
            setCloudId(event.detail.value);
          }}
        >
          {juiceGroupClouds?.map((item) => (
            <ScaleDropdownSelectItem key={item.id} value={item.id}>
              {item.name}
            </ScaleDropdownSelectItem>
          ))}
        </ScaleDropdownSelect>
      </div>
      <Section header="Forecast Summary" divider>
        {cloudId
          ? <ForecastSummary juiceGroup={juiceGroup} cloudId={cloudId} />
          : <p>No Cloud selected</p>}
      </Section>
      <Section header="Virtual Cloud Summary" divider>
        {cloudId
          ? (
            <VirtualCloudsSummary
              virtualCloud={virtualClouds.find((vc) => vc.cloud.name === cloudId)}
            />
          )
          : <p>No Cloud selected</p>}
      </Section>
      <Section header="Usage Summary" divider>
        {cloudId
          ? <UsageSummary juiceGroup={juiceGroup} cloudId={cloudId} />
          : <p>No Cloud selected</p>}
      </Section>
    </>
  );
};

Summaries.propTypes = {
  juiceGroup: PropTypes.string.isRequired,
};

export default Summaries;
