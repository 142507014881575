import http from './httpClient';

class Auth {
  static resetUserPassword(data) {
    return http.post(`${import.meta.env.VITE_AUTH_V0_BASE}/user/reset-password`, data);
  }

  static activateUser(data) {
    return http.post(`${import.meta.env.VITE_AUTH_V0_BASE}/user/activate`, data);
  }

  static updateUserPassword(data) {
    return http.post(`${import.meta.env.VITE_AUTH_V0_BASE}/user/change-password`, data);
  }

  static resetUserPasswordLink(data) {
    return http.post(`${import.meta.env.VITE_AUTH_V0_BASE}/user/reset-password-link`, data);
  }

  static fetchV1MarketplaceItems() {
    return http.get(`${import.meta.env.VITE_AUTH_V1_BASE}/marketplace-items`);
  }

  static fetchV2Users(params) {
    return http.get(`${import.meta.env.VITE_AUTH_V2_BASE}/users`, { params });
  }

  static fetchV2UserByUuid(uuid) {
    return http.get(`${import.meta.env.VITE_AUTH_V2_BASE}/users/${uuid}`);
  }

  static validateVerificationToken(verificationToken) {
    return http.get(`${import.meta.env.VITE_AUTH_V2_BASE}/users/token/${verificationToken}`);
  }

  static createV2User(data) {
    return http.post(`${import.meta.env.VITE_AUTH_V2_BASE}/users`, data);
  }

  static updateV2User(data) {
    return http.put(`${import.meta.env.VITE_AUTH_V2_BASE}/users/${data.uuid}`, data);
  }

  static deleteV2User(uuid) {
    return http.delete(`${import.meta.env.VITE_AUTH_V2_BASE}/users/${uuid}`);
  }

  static assignV2Product(data) {
    return http.put(`${import.meta.env.VITE_AUTH_V2_BASE}/users/assign-product`, data);
  }

  static unassignV2Product(data) {
    return http.put(`${import.meta.env.VITE_AUTH_V2_BASE}/users/unassign-product`, data);
  }

  static fetchV1UserResources(uuid) {
    return http.get(`${import.meta.env.VITE_AUTH_V1_BASE}/users/${uuid}/resources`);
  }

  static fetchV1Organizations(params) {
    return http.get(`${import.meta.env.VITE_AUTH_V1_BASE}/organizations`, { params });
  }

  static fetchV1OrganizationById(id) {
    return http.get(`${import.meta.env.VITE_AUTH_V1_BASE}/organizations/${id}`);
  }

  static createV1Organization(data) {
    return http.post(`${import.meta.env.VITE_AUTH_V1_BASE}/organizations`, data);
  }

  static updateV1Organization(data) {
    return http.put(`${import.meta.env.VITE_AUTH_V1_BASE}/organizations/${data._id}`, data);
  }

  static activateMarketplaceItem(data) {
    return http
      .put(`${import.meta.env.VITE_AUTH_V1_BASE}/organizations/${data._id}/activate-marketplaceitem`, data);
  }

  static fetchV1BusinessUnits(params) {
    return http.get(`${import.meta.env.VITE_AUTH_V1_BASE}/business-units`, { params });
  }

  static fetchV1BusinessUnit(id) {
    return http.get(`${import.meta.env.VITE_AUTH_V1_BASE}/business-units/${id}`);
  }

  static createV1BusinessUnit(data) {
    return http.post(`${import.meta.env.VITE_AUTH_V1_BASE}/business-units`, data);
  }

  static updateV1BusinessUnit(data) {
    return http.patch(`${import.meta.env.VITE_AUTH_V1_BASE}/business-units/${data._id}`, data);
  }

  static deleteV1BusinessUnit(id) {
    return http.delete(`${import.meta.env.VITE_AUTH_V1_BASE}/business-units/${id}`);
  }

  static createApiKey(password) {
    return http.post(`${import.meta.env.VITE_AUTH_V1_BASE}/users/api-key`, { password });
  }

  static createV2Project(data) {
    return http.post(`${import.meta.env.VITE_AUTH_V2_BASE}/projects`, data);
  }

  static fetchV2Projects(params) {
    return http.get(`${import.meta.env.VITE_AUTH_V2_BASE}/projects`, { params });
  }

  static fetchV2ProjectById(id) {
    return http.get(`${import.meta.env.VITE_AUTH_V2_BASE}/projects/${id}`);
  }

  static updateV2Project(data) {
    return http.patch(`${import.meta.env.VITE_AUTH_V2_BASE}/projects/${data._id}`, data);
  }

  static deleteV2Project(id) {
    return http.delete(`${import.meta.env.VITE_AUTH_V2_BASE}/projects/${id}`);
  }

  static createV2Product(data) {
    return http.post(`${import.meta.env.VITE_AUTH_V2_BASE}/products`, data);
  }

  static fetchV2Products(params) {
    return http.get(`${import.meta.env.VITE_AUTH_V2_BASE}/products`, { params });
  }

  static fetchV2ProductById(id, enrich = true) {
    return http.get(`${import.meta.env.VITE_AUTH_V2_BASE}/products/${id}`, { params: { enrich } });
  }

  static updateV2Product(data) {
    return http.patch(`${import.meta.env.VITE_AUTH_V2_BASE}/products/${data.id}`, data);
  }

  static deleteV2Product(id) {
    return http.delete(`${import.meta.env.VITE_AUTH_V2_BASE}/products/${id}`);
  }

  static fetchV2JuiceGroups({ offset, limit, search: juiceGroup }) {
    return http.get(`${import.meta.env.VITE_AUTH_V2_BASE}/reports/juicegroups`, { params: { offset, limit, juiceGroup } });
  }
}

export default Auth;
