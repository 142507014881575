import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {
  Formik,
  Form,
} from 'formik';
import { ScaleButton } from '@telekom/scale-components-react';
import { Grid } from '@mui/material';
import {
  FormikSelect,
  FormikTextField,
} from '../../../components/Formik/index';
import { regexValidator } from '../../../helpers/regexValidator';

import useApiCall from '../../../hooks/useApiCall';
import Auth from '../../../api/auth';


const EditOrganizationForm = ({ onSubmit, onCancel, organization }) => {
  const validationSchema = Yup.object().shape({
    enterpriseName: Yup.string().required('Required').matches(regexValidator.name, 'Start with letter, all lower-case, no spaces, no special characters(dash only)'),
  });

  const fetchAccountOwners = useApiCall(Auth.fetchV2Users, null, null);
  const [accountOwners, setAccountOwners] = useState();

  useEffect(() => {
    async function getAccountOwners() {
      const [data] = await fetchAccountOwners({ organizationId: organization._id, roles: ['ACCOUNT_OWNER'] });
      if (data) setAccountOwners(data);
    }
    getAccountOwners();
  }, []);

  const initialValues = {
    enterpriseName: organization.enterpriseName,
    CRMId: organization.CRMId,
    accountOwnerId: organization.accountOwnerId || '',
  };

  const menuItems = accountOwners?.map((accountOwner) => ({
    value: accountOwner._id,
    label: accountOwner.email,
  }));

  return (
    <Formik
      enableReinitialize
      validateOnChange
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ dirty, isValid }) => (
        <Form>
          <Grid
            container
            paddingTop="1rem"
            rowGap={2}
            marginBottom="1rem"
            justifyContent="end"
          >
            <FormikTextField
              autoFocus
              name="enterpriseName"
              label="Name"
            />
            <FormikTextField
              name="CRMId"
              label="CRM Id"
            />
            { accountOwners && (
            <FormikSelect
              name="accountOwnerId"
              label="Account Owner"
              menuItems={menuItems}
            />
            )}
            <ScaleButton
              variant="primary"
              disabled={!dirty || !isValid}
            >
              Save
            </ScaleButton>
            <ScaleButton
              type="button"
              variant="secondary"
              style={{ marginLeft: '1em' }}
              onClick={onCancel}
            >
              Cancel
            </ScaleButton>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

EditOrganizationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  organization: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.array,
      PropTypes.object,
    ]),
  ).isRequired,
};


export default EditOrganizationForm;
