import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { requestStarted, requestFullfilled, requestFailed } from '../redux/status/actions';
import { enqueueSnackbar } from '../redux/notifier/actions';

function useApiCall(promise, successMessage, errorMessage) {
  const dispatch = useDispatch();
  const [error, setError] = useState();

  useEffect(() => {
    if (error) {
      dispatch(requestFailed());
      if (error.status === 404) {
        throw new Error(error.message);
      } else {
        dispatch(enqueueSnackbar({
          message: errorMessage || error.message,
          options: { variant: 'error' },
        }));
      }
    }
  }, [error]);

  const makeApiCall = async (...args) => {
    try {
      dispatch(requestStarted());
      const response = await promise(...args);
      const { data = 'success' } = response;
      if (successMessage) {
        dispatch(enqueueSnackbar({
          message: successMessage,
          options: { variant: 'success' },
        }));
      }
      dispatch(requestFullfilled());
      return [data, null];
    } catch (err) {
      setError(err);
      return [null, err];
    }
  };

  return makeApiCall;
}

export default useApiCall;
