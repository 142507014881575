import { configureStore, Tuple } from '@reduxjs/toolkit';
import { createEpicMiddleware } from 'redux-observable';
import * as Sentry from '@sentry/react';
import reducer from './reducer';
import adminEpics from './admin/epics';
import safescarfEpics from './safescarf/epics';
import virtualCloudsEpics from './virtualClouds/epics';
import userEpics from './user/epics';

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

const createReduxStore = (state = {}) => {
  const epicMiddleware = createEpicMiddleware();

  const store = configureStore({
    reducer,
    middleware: () => new Tuple(epicMiddleware),
    preloadedState: state,
    enhancers: (getDefaultEnhancers) => getDefaultEnhancers().concat(sentryReduxEnhancer),
  });

  epicMiddleware.run(adminEpics);
  epicMiddleware.run(safescarfEpics);
  epicMiddleware.run(virtualCloudsEpics);
  epicMiddleware.run(userEpics);

  return store;
};

export default createReduxStore;
