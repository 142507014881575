import { ofType, combineEpics } from 'redux-observable';
import { mergeMap, catchError } from 'rxjs/operators';
import { from, of } from 'rxjs';

import Safescarf from '../../../api/safescarf';

import {
  CREATE_PRODUCT_TYPE_MEMBER_STARTED,
  createProductTypeMemberFullfilled,
  FETCH_PRODUCT_TYPE_MEMBERS_STARTED,
  fetchProductTypeMembersFullfilled,
  UPDATE_PRODUCT_TYPE_MEMBER_STARTED,
  updateProductTypeMemberFullfilled,
  DELETE_PRODUCT_TYPE_MEMBER_STARTED,
  deleteProductTypeMemberFullfilled,
} from './actions';
import { enqueueSnackbar } from '../../notifier/actions';
import { requestFailed } from '../../status/actions';

const createProductTypeMemberEpic = (action$) => action$.pipe(
  ofType(CREATE_PRODUCT_TYPE_MEMBER_STARTED),
  mergeMap((action) => from(Safescarf.createSafescarfProductTypeMember(action.payload)).pipe(
    mergeMap((response) => of(
      createProductTypeMemberFullfilled(response.data),
    )),
    catchError((error) => of(
      requestFailed(),
      enqueueSnackbar({
        message: error.message,
        options: { variant: 'error' },
      }),
    )),
  )),
);
const fetchProductTypeMembersEpic = (action$) => action$.pipe(
  ofType(FETCH_PRODUCT_TYPE_MEMBERS_STARTED),
  mergeMap((action) => from(Safescarf.fetchSafescarfProductTypeMembers(action.payload)).pipe(
    mergeMap((response) => of(
      fetchProductTypeMembersFullfilled(response.data),
    )),
    catchError((error) => of(
      requestFailed(),
      enqueueSnackbar({
        message: error.message,
        options: { variant: 'error' },
      }),
    )),
  )),
);
const updateProductTypeMemberEpic = (action$) => action$.pipe(
  ofType(UPDATE_PRODUCT_TYPE_MEMBER_STARTED),
  mergeMap((action) => from(Safescarf.updateSafescarfProductTypeMember(action.payload)).pipe(
    mergeMap((response) => of(
      updateProductTypeMemberFullfilled(response.data),
    )),
    catchError((error) => of(
      requestFailed(),
      enqueueSnackbar({
        message: error.message,
        options: { variant: 'error' },
      }),
    )),
  )),
);
const deleteProductTypeMemberEpic = (action$) => action$.pipe(
  ofType(DELETE_PRODUCT_TYPE_MEMBER_STARTED),
  mergeMap((action) => from(Safescarf.deleteSafescarfProductTypeMember(action.payload)).pipe(
    mergeMap(() => of(
      deleteProductTypeMemberFullfilled(action.payload),
    )),
    catchError((error) => of(
      requestFailed(),
      enqueueSnackbar({
        message: error.message,
        options: { variant: 'error' },
      }),
    )),
  )),
);

export default combineEpics(
  createProductTypeMemberEpic,
  fetchProductTypeMembersEpic,
  updateProductTypeMemberEpic,
  deleteProductTypeMemberEpic,
);
