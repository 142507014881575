import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ScaleButton } from '@telekom/scale-components-react';
import { Grid } from '@mui/material';
import {
  Formik,
  Form,
} from 'formik';
import {
  FormikSelect,
  FormikTextField,
} from '../../../components/Formik/index';
import { regexValidator } from '../../../helpers/regexValidator';
import useApiCall from '../../../hooks/useApiCall';
import Auth from '../../../api/auth';

const CreateProjectForm = ({ onSubmit, onCancel }) => {
  const { organizationId } = useParams();
  const fetchBusinessUnits = useApiCall(Auth.fetchV1BusinessUnits);
  const fetchUsers = useApiCall(Auth.fetchV2Users);
  const [businessUnits, setBusinessUnits] = useState(null);
  const [technicalLeads, setTechnicalLeads] = useState(null);

  const initialValues = {
    name: '',
    description: '',
    businessUnitId: null,
    technicalLeadId: null,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required('Required')
      .matches(
        regexValidator.name,
        'All lower-case, no spaces, no special characters (dash only)',
      ),
    description: Yup.string()
      .required('Required'),
  });

  const businessUnitsItems = businessUnits?.map((businessUnit) => ({
    value: businessUnit._id,
    label: businessUnit.name,
  }));

  const technicalLeadsItems = technicalLeads?.map((technicalLead) => ({
    value: technicalLead._id,
    label: technicalLead.email,
  }));

  async function getBusinessUnits() {
    const [data] = await fetchBusinessUnits({ organizationId });
    if (data) setBusinessUnits(data);
  }

  async function getTechnicalLeads() {
    const [data] = await fetchUsers({ organizationId, roles: ['TECHNICAL_LEAD'] });
    if (data) setTechnicalLeads(data);
  }

  useEffect(() => {
    getBusinessUnits();
    getTechnicalLeads();
  }, []);

  return (
    <Formik
      validateOnChange
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ dirty, isValid }) => (
        <Form>
          <Grid
            container
            gap={2}
            py={2}
          >
            <Grid item xs={12}>
              <FormikTextField
                name="name"
                label="Project Name"
              />
            </Grid>
            <Grid item xs={12}>
              <FormikTextField
                name="description"
                label="Description"
              />
            </Grid>
            <Grid item xs={12}>
              <FormikSelect
                name="businessUnitId"
                label="Business Unit"
                menuItems={businessUnitsItems}
              />
            </Grid>
            <Grid item xs={12}>
              <FormikSelect
                name="technicalLeadId"
                label="Technical Lead"
                menuItems={technicalLeadsItems}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                justifyContent="flex-end"
                gap={1}
              >
                <Grid item>
                  <ScaleButton
                    variant="primary"
                    disabled={!dirty || !isValid}
                  >
                    Create
                  </ScaleButton>
                </Grid>
                <Grid item>
                  <ScaleButton
                    type="button"
                    variant="secondary"
                    onClick={onCancel}
                  >
                    Cancel
                  </ScaleButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

CreateProjectForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};


export default CreateProjectForm;
