import {
  FETCH_VIRTUAL_CLOUDS_FULLFILLED,
} from './actions';

const virtualCloudsReducer = (state = [], action) => {
  switch (action.type) {
    case FETCH_VIRTUAL_CLOUDS_FULLFILLED:
      return action.payload;
    default:
      return state;
  }
};

export { virtualCloudsReducer };
