import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { ScaleModal } from '@telekom/scale-components-react';

import rbacCheck from '../../../../rbac/rbacCheck';
import { fetchProducts } from '../../../../redux/admin/products/actions';
import CreateProductForm from './CreateProductForm';
import Table from '../../../../components/Table';

const Products = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intl = useIntl();
  const formRef = useRef(null);
  const { projectId } = useParams();
  const userData = useSelector((state) => state.user);
  const products = useSelector((state) => state.admin.products)
    .filter((product) => product.projectId === projectId);

  const [createProductModalOpened, setCreateProductModalOpened] = useState(false);

  const canCreateProduct = (products.length === 0 || products.find((product) => product.type !== 'evaluation'))
  && rbacCheck(userData.roles, '/products:POST');

  const CreateProductTooltip = () => {
    if (products.find((product) => product.type === 'evaluation')) return intl.formatMessage({ id: 'evaluationProductCreationDisabled' });
    return intl.formatMessage({ id: 'productCreationPermissionErr' });
  };


  useEffect(() => {
    if (!products.length) { dispatch(fetchProducts({ projectId })); }
  }, []);

  const rows = products.map((product) => ({
    key: product._id,
    onRowClick: () => navigate(`/products/${product._id}`),
    data: [
      {
        field: 'name',
        value: product.name,
      },
      {
        field: 'description',
        value: product.description,
      },
      {
        field: 'type',
        value: product.type,
      },
      {
        field: 'juiceGroup',
        value: product.details.juiceGroup,
      },
      {
        field: 'approved',
        value: product.approved ? 'True' : 'False',
      },
      {
        field: 'actions',
        value: 'tags',
        tags: product.details.tags,
      },
    ],
  }));

  const productTableFields = [
    {
      title: 'Name',
      id: 'name',
    },
    {
      title: 'Description',
      id: 'description',
    },
    {
      title: 'Type',
      id: 'type',
    },
    {
      title: 'Juicegroup',
      id: 'juicegroup',
    },
    {
      title: 'Approved',
      id: 'approved',
    },
    {
      title: 'Tags',
      id: 'tags',
    },
  ];

  return (
    <>
      {createProductModalOpened && (
      <ScaleModal
        heading="Create new product"
        size="small"
        opened={createProductModalOpened}
        onScale-close={() => setCreateProductModalOpened(false)}
      >
        <CreateProductForm
          formRef={formRef}
          onSubmit={() => setCreateProductModalOpened(false)}
          onCancel={() => setCreateProductModalOpened(false)}
        />
      </ScaleModal>
      )}
      <Table
        header="Products"
        fields={productTableFields}
        rows={rows}
        createAction={{
          disabled: !canCreateProduct,
          onClick: () => setCreateProductModalOpened(true),
          tooltip: !canCreateProduct ? CreateProductTooltip() : '',
        }}
      />
    </>
  );
};

export default Products;
