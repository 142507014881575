/* eslint-disable react/prop-types */
import { useLocation } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';

const Authenticator = ({ children }) => {
  const { keycloak, initialized } = useKeycloak();
  const { pathname } = useLocation();

  const paths = [
    '/account',
    '/settings',
    '/organizations',
    '/organization',
    '/products/compute',
    '/products/safescarf',
    '/products/caas-on-openstack',
    '/products/compute/order',
    '/products/compute/free-trial',
    '/products/safescarf/order',
    '/reports',
    '/demand-management',
    '/monitoring-dashboard',
  ];

  const notAuthenticatedPath = [
    '/products/compute/specification',
    '/products/webshield/specification',
    '/products/safescarf/specification',
    '/products/caas-on-openstack/specification',
  ];

  if (!initialized) return null;

  const authenticatedPath = paths.find(
    (path) => pathname.startsWith(path) && !notAuthenticatedPath.includes(pathname),
  );
  if (!keycloak.authenticated && authenticatedPath) {
    keycloak.login({ redirectUri: `${window.location.origin}/${pathname}` });
  }

  return children;
};

export default Authenticator;
