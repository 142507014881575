import { ofType, combineEpics } from 'redux-observable';
import { mergeMap, catchError } from 'rxjs/operators';
import { from, of } from 'rxjs';

import Safescarf from '../../../api/safescarf';

import {
  CREATE_SAFESCARF_PRODUCT_STARTED,
  createProductFullfilled,
  FETCH_SAFESCARF_PRODUCTS_STARTED,
  fetchProductsFullfilled,
  UPDATE_SAFESCARF_PRODUCT_STARTED,
  updateProductFullfilled,
  DELETE_SAFESCARF_PRODUCT_STARTED,
  deleteProductFullfilled,
} from './actions';
import { fetchProductMembers } from '../productMembers/actions';
import { enqueueSnackbar } from '../../notifier/actions';
import { requestFailed } from '../../status/actions';

const createProductEpic = (action$) => action$.pipe(
  ofType(CREATE_SAFESCARF_PRODUCT_STARTED),
  mergeMap((action) => from(Safescarf.createSafescarfProduct(action.payload)).pipe(
    mergeMap((response) => of(
      createProductFullfilled(response.data),
      fetchProductMembers(),
    )),
    catchError((error) => of(
      requestFailed(),
      enqueueSnackbar({
        message: error.message,
        options: { variant: 'error' },
      }),
    )),
  )),
);
const fetchProductsEpic = (action$) => action$.pipe(
  ofType(FETCH_SAFESCARF_PRODUCTS_STARTED),
  mergeMap((action) => from(Safescarf.fetchSafescarfProducts(action.payload)).pipe(
    mergeMap((response) => of(
      fetchProductsFullfilled(response.data),
    )),
    catchError((error) => of(
      requestFailed(),
      enqueueSnackbar({
        message: error.message,
        options: { variant: 'error' },
      }),
    )),
  )),
);
const updateProductEpic = (action$) => action$.pipe(
  ofType(UPDATE_SAFESCARF_PRODUCT_STARTED),
  mergeMap((action) => from(Safescarf.updateSafescarfProduct(action.payload)).pipe(
    mergeMap((response) => of(
      updateProductFullfilled(response.data),
    )),
    catchError((error) => of(
      requestFailed(),
      enqueueSnackbar({
        message: error.message,
        options: { variant: 'error' },
      }),
    )),
  )),
);
const deleteProductEpic = (action$) => action$.pipe(
  ofType(DELETE_SAFESCARF_PRODUCT_STARTED),
  mergeMap((action) => from(Safescarf.deleteSafescarfProduct(action.payload)).pipe(
    mergeMap(() => of(
      deleteProductFullfilled(action.payload),
    )),
    catchError((error) => of(
      requestFailed(),
      enqueueSnackbar({
        message: error.message,
        options: { variant: 'error' },
      }),
    )),
  )),
);

export default combineEpics(
  createProductEpic,
  fetchProductsEpic,
  updateProductEpic,
  deleteProductEpic,
);
