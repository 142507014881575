import { combineReducers } from 'redux';

import { organizationsAdminReducer } from './organizations/reducer';
import { usersAdminReducer } from './users/reducer';
import { marketplaceItemsAdminReducer } from './marketplaceItems/reducer';
import { businessUnitsAdminReducer } from './businessUnits/reducer';
import { projectsAdminReducer } from './projects/reducer';
import { productsAdminReducer } from './products/reducer';

export default combineReducers({
  organizations: organizationsAdminReducer,
  users: usersAdminReducer,
  marketplaceItems: marketplaceItemsAdminReducer,
  businessUnits: businessUnitsAdminReducer,
  projects: projectsAdminReducer,
  products: productsAdminReducer,
});
