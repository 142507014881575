import {
  FETCH_BUSINESS_UNITS_FULLFILLED,
  CREATE_BUSINESS_UNIT_FULLFILLED,
  UPDATE_BUSINESS_UNIT_FULLFILLED,
  DELETE_BUSINESS_UNIT_FULLFILLED,
  DELETE_BUSINESS_UNIT_STARTED,
  CLEAR_DELETION_STATUS,
} from './actions';

const initialState = {
  data: [],
  deletionStatus: null,
};

const businessUnitsAdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_BUSINESS_UNIT_FULLFILLED:
      return {
        ...state,
        data: [...state.data, action.payload],
      };
    case FETCH_BUSINESS_UNITS_FULLFILLED:
      return {
        ...state,
        data: action.payload,
      };
    case UPDATE_BUSINESS_UNIT_FULLFILLED:
      return {
        ...state,
        data: state.data.map((businessUnit) => {
          if (businessUnit._id === action.payload._id) {
            return action.payload;
          }
          return businessUnit;
        }),
      };
    case DELETE_BUSINESS_UNIT_STARTED:
      return {
        ...state,
        deletionStatus: 'pending',
      };
    case DELETE_BUSINESS_UNIT_FULLFILLED:
      return {
        ...state,
        data: state.data.filter((businessUnit) => businessUnit._id !== action.payload._id),
        deletionStatus: 'success',
      };
    case CLEAR_DELETION_STATUS:
      return {
        ...state,
        deletionStatus: null,
      };
    default:
      return state;
  }
};

export { businessUnitsAdminReducer };
