/* eslint-disable max-len */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Container, Typography } from '@mui/material';
import { ScaleModal } from '@telekom/scale-components-react';

import Auth from '../../../../api/auth';
import Provision from '../../../../api/provision';
import useApiCall from '../../../../hooks/useApiCall';

import AccordionContainer from '../../../../components/AccordionContainer';
import EvaluationForm from './EvaluationForm';
import Success from '../../../../components/Success';

const trialQuota = {
  compute: { cores: 10, ram: 1024 },
  network: { floatingIps: 1 },
  volume: { gigabytes: 10, objectsMegabytes: 0 },
};

const Description = () => (
  <>
    <Typography gutterBottom>
      The evaluation product enables user to place workloads on the pan-net.cloud
      using Cloud APIs and a full set of user documentation.
      The product includes 10 vCPUs, 10GB RAM and 10GB storage.
      The product is available for a 3 month testing period starting from order confirmation.
      An organization can order 1 evaluation products in total within a time frame of
      one year starting from the creation of the organization in the pan-net.cloud portal.
    </Typography>
    <Typography gutterBottom>
      This product is governed by the General Terms & Conditions (GT&C) for using pan-net.cloud.
    </Typography>
    <Typography gutterBottom>
      The Product Specification for evaluation products
      is applicable as published on the pan-net.cloud portal.
    </Typography>
    <Typography gutterBottom>
      The Service Level Agreement is not applicable for evaluation products.
    </Typography>
  </>
);

function renderEvaluationDetails(trialYearRevoked, trialEnabled, virtualCloud, trialProduct) {
  if (trialYearRevoked) {
    return <Typography>Your right to use the free trial product has expired.</Typography>;
  }
  if (!trialEnabled) {
    return <Typography>Trial product is not enabled for your organization.</Typography>;
  }
  if (!trialProduct) {
    return <Typography>You are not assigned to trial product.</Typography>;
  }
  if (virtualCloud.projects?.length) {
    return (
      <>
        <Typography display="inline">Your compute package is allocated to the trial product of your organization, </Typography>
        <Typography variant="h6" display="inline">{trialProduct.name}</Typography>
        <Typography gutterBottom>
          Navigate to the products page by clicking
          {' '}
          <Link to="/products/compute">
            <Typography component="span" display="inline">here</Typography>
          </Link>
        </Typography>
      </>
    );
  }
  return (
    <>
      <Typography display="inline">Your compute package will be allocated to trial product of your organization, </Typography>
      <Typography gutterBottom variant="h6" display="inline">{trialProduct?.name}</Typography>
    </>
  );
}

const Evaluation = () => {
  const fetchProducts = useApiCall(Auth.fetchV2Products, null, null);
  const fetchVirtualClouds = useApiCall(Provision.fetchVirtualClouds, null, null);
  const createOsProject = useApiCall(Provision.createOsProject, null, null);
  const navigate = useNavigate();
  const userData = useSelector((state) => state.user);
  const [trialProduct, setTrialProduct] = useState();
  const [virtualCloud, setVirtualCloud] = useState({});
  const [osProjectOrdered, setOsProjectOrdered] = useState(false);

  const yearInMilliseconds = 365 * 24 * 3600 * 1000;
  const trialYearRevoked = new Date() - new Date(userData.organization.createdAt) > yearInMilliseconds;
  const trialEnabled = userData.organization.marketplaceItemIds?.find((i) => i.name === 'Evaluation');

  useEffect(() => {
    async function getTrialProduct() {
      const [data] = await fetchProducts({ type: 'evaluation' });
      setTrialProduct(data[0]);
    }
    if (!trialYearRevoked && trialEnabled) getTrialProduct();
  }, [trialYearRevoked, trialEnabled]);

  useEffect(() => {
    async function getTrialVirtualCloud() {
      const { juiceGroup } = trialProduct.details;
      const [data] = await fetchVirtualClouds({ juiceGroups: [juiceGroup] });
      setVirtualCloud({
        id: data[0].cloud.name,
        projects: data[0].projects,
      });
    }
    if (trialProduct) getTrialVirtualCloud();
  }, [trialProduct]);

  const handleSubmit = async ({ osProjectName }) => {
    const [data] = await createOsProject({
      osProjectName,
      cloudId: virtualCloud.id,
      type: 'computeTrial',
      juiceGroup: trialProduct.details.juiceGroup,
      projectId: trialProduct._id,
      productId: trialProduct._id,
      organizationId: userData.organizationId,
      requestedQuota: trialQuota,
    });
    if (data !== null) {
      setOsProjectOrdered(true);
    }
  };

  return (
    <Container maxWidth="md">
      {osProjectOrdered && (
        <ScaleModal
          opened
          heading="Evaluation OS-Project Ordered"
          onScale-close={() => {
            navigate('/products/compute');
          }}
        >
          <Success
            messageId="createOsProjectFullfilled"
            onSubmit={() => {
              navigate('/products/compute');
            }}
          />
        </ScaleModal>
      )}
      <Typography variant="h4" color="primary" display="inline">Evaluation product</Typography>
      <Typography variant="h5" display="inline"> (Free of charge)</Typography>
      <AccordionContainer title="Project Description" details={<Description />}>
        { renderEvaluationDetails(trialYearRevoked, trialEnabled, virtualCloud, trialProduct) }
        <Typography>Define a name for the OpenStack project (tenant):</Typography>
        <EvaluationForm
          disabled={!trialProduct || (trialYearRevoked || !trialEnabled || Boolean(virtualCloud.projects?.length))}
          onSubmit={handleSubmit}
        />
      </AccordionContainer>
    </Container>
  );
};

export default Evaluation;
