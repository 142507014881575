/* eslint-disable react/prop-types */
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Table from '../../../../../components/Table';
import rbacCheck from '../../../../../rbac/rbacCheck';

const OsProjects = ({ osProjects, product }) => {
  const navigate = useNavigate();
  const userData = useSelector((state) => state.user);

  const canUserNavigateToDetails = (osProjectName) => {
    const osProject = osProjects.find((project) => project.name === osProjectName);
    const canPatch = rbacCheck(userData.roles, '/osprojects:PATCH', {
      productId: product._id,
      osProjectType: osProject.type,
      userProducts: userData.productIds,
    });

    const canDelete = rbacCheck(userData.roles, '/osprojects:DELETE', {
      productId: product._id,
      osProjectType: osProject.type,
      userProducts: userData.productIds,
    });

    if (osProject.status === 'deletionFailed') {
      return canPatch && canDelete;
    }

    return osProject.status === 'active' && canDelete;
  };

  const osProjectsTableRows = osProjects.map((osProject) => ({
    key: osProject.name,
    onRowClick: canUserNavigateToDetails(osProject.name) ? () => (
      navigate(`osprojects/${product.details.juiceGroup}/${osProject.cloudId}/${osProject.name}`)
    ) : undefined,
    data: [
      { field: 'name', value: osProject.name },
      { field: 'cloudId', value: osProject.cloudId },
      { field: 'expirationDate', value: osProject.expirationDate ? osProject.expirationDate.split('T')[0] : '-' },
      { field: 'status', value: osProject.status },
    ],
  }));


  return (
    <Table
      header="OS Projects"
      fields={[
        { id: 'name', title: 'Name' },
        { id: 'cloudId', title: 'Cloud' },
        { id: 'expirationDate', title: 'Expiration Date' },
        { id: 'status', title: 'Status' },
      ]}
      rows={osProjectsTableRows}
    />
  );
};

OsProjects.propTypes = {
  osProjects: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    juiceGroup: PropTypes.string.isRequired,
    cloudId: PropTypes.string.isRequired,
    expirationDate: PropTypes.string,
    status: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  })).isRequired,
  product: PropTypes.shape({
    _id: PropTypes.string.isRequired,
  }).isRequired,
};

export default OsProjects;
