import {
  ORDER_INSTANCE_FULLFILLED,
  FETCH_INSTANCE_FULLFILLED,
} from './actions';

const initialState = { orderStatus: '', data: {} };

const instanceReducer = (state = initialState, action) => {
  switch (action.type) {
    case ORDER_INSTANCE_FULLFILLED:
      return {
        ...state,
        orderStatus: 'ordered',
      };
    case FETCH_INSTANCE_FULLFILLED:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

export { instanceReducer };

