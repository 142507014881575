import { useEffect, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { useDispatch, useSelector } from 'react-redux';
import { RouterProvider } from 'react-router-dom';

import { ThemeProvider } from '@mui/material';
import { fetchUserData } from './redux/user/actions';
import getRouter from './AppRouter';
import { createCustomTheme } from './muiTheme';

const App = () => {
  const dispatch = useDispatch();
  const { keycloak, initialized } = useKeycloak();
  const [isDarkMode, setIsDarkMode] = useState(localStorage.getItem('theme') === 'dark');
  const theme = createCustomTheme(isDarkMode ? 'dark' : 'light');

  const applyTheme = (newTheme) => {
    document.documentElement.dataset.mode = newTheme;
    document.body.dataset.mode = newTheme;
  };

  useEffect(() => {
    const mq = window.matchMedia('(prefers-color-scheme: dark)');
    const storedTheme = localStorage.getItem('theme');
    const systemPrefersDark = mq.matches;

    const initialTheme = storedTheme || (systemPrefersDark ? 'dark' : 'light');
    applyTheme(initialTheme);
    setIsDarkMode(initialTheme === 'dark');

    const handleChange = (e) => {
      const newMode = e.matches ? 'dark' : 'light';
      applyTheme(newMode);
      localStorage.setItem('theme', newMode);
      setIsDarkMode(newMode === 'dark');
    };

    mq.addEventListener('change', handleChange);

    return () => {
      mq.removeEventListener('change', handleChange);
    };
  }, []);

  useEffect(() => {
    if (initialized && keycloak.authenticated) {
      const { email } = keycloak.tokenParsed;
      dispatch(fetchUserData({ email }));
    }
  }, [initialized, keycloak.authenticated]);

  // We need the user data, so that we can have a user-based router
  const user = useSelector((state) => state.user);

  const switchMode = () => {
    const newMode = isDarkMode ? 'light' : 'dark';
    applyTheme(newMode);
    localStorage.setItem('theme', newMode); // Persist user preference
    setIsDarkMode(!isDarkMode);
  };

  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={getRouter(user, switchMode)} />
    </ThemeProvider>
  );
};

export default App;
