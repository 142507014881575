import { ofType, combineEpics } from 'redux-observable';
import { mergeMap, catchError } from 'rxjs/operators';
import { from, of } from 'rxjs';

import Safescarf from '../../../api/safescarf';

import {
  FETCH_MACHINE_ACCOUNTS_STARTED,
  fetchMachineAccountsFullfilled,
  createMachineAccountFullfilled,
  CREATE_MACHINE_ACCOUNT_STARTED,
} from './actions';
import { enqueueSnackbar } from '../../notifier/actions';
import { requestFailed } from '../../status/actions';

const fetchMachineAccountsEpic = (action$) => action$.pipe(
  ofType(FETCH_MACHINE_ACCOUNTS_STARTED),
  mergeMap(() => from(Safescarf.fetchMachineAccounts()).pipe(
    mergeMap((response) => of(
      fetchMachineAccountsFullfilled(response.data),
    )),
    catchError((error) => of(
      requestFailed(),
      enqueueSnackbar({
        message: error.message,
        options: { variant: 'error' },
      }),
    )),
  )),
);

const createMachineAccountEpic = (action$) => action$.pipe(
  ofType(CREATE_MACHINE_ACCOUNT_STARTED),
  mergeMap(() => from(Safescarf.createMachineAccount()).pipe(
    mergeMap((response) => of(
      createMachineAccountFullfilled(response.data),
    )),
    catchError((error) => of(
      requestFailed(),
      enqueueSnackbar({
        message: error.message,
        options: { variant: 'error' },
      }),
    )),
  )),
);

export default combineEpics(
  createMachineAccountEpic,
  fetchMachineAccountsEpic,
);
