import { ofType, combineEpics } from 'redux-observable';
import { mergeMap, catchError } from 'rxjs/operators';
import { from, of } from 'rxjs';

import Safescarf from '../../../api/safescarf';

import {
  ORDER_INSTANCE_STARTED,
  orderInstanceFullfilled,
  FETCH_INSTANCE_STARTED,
  fetchInstancefullfilled,
} from './actions';
import { enqueueSnackbar } from '../../notifier/actions';
import { requestFailed } from '../../status/actions';

const orderInstanceEpic = (action$) => action$.pipe(
  ofType(ORDER_INSTANCE_STARTED),
  mergeMap((action) => from(Safescarf.createSafescarfInstance(action.payload)).pipe(
    mergeMap(() => of(
      orderInstanceFullfilled(),
    )),
    catchError((error) => of(
      requestFailed(),
      enqueueSnackbar({
        message: error.message,
        options: { variant: 'error' },
      }),
    )),
  )),
);

const fetchInstanceEpic = (action$) => action$.pipe(
  ofType(FETCH_INSTANCE_STARTED),
  mergeMap(() => from(Safescarf.fetchSafescarfInstance()).pipe(
    mergeMap((response) => of(
      fetchInstancefullfilled(response),
    )),
    catchError((error) => of(
      requestFailed(),
      enqueueSnackbar({
        message: error.message,
        options: { variant: 'error' },
      }),
    )),
  )),
);

export default combineEpics(
  orderInstanceEpic,
  fetchInstanceEpic,
);
