import { combineEpics } from 'redux-observable';

import usersEpics from './users/epics';
import organizationsEpics from './organizations/epics';
import businessUnitsEpics from './businessUnits/epics';
import marketplaceItemsEpics from './marketplaceItems/epics';
import projectsEpics from './projects/epics';
import productsEpics from './products/epics';

export default combineEpics(
  usersEpics,
  organizationsEpics,
  businessUnitsEpics,
  marketplaceItemsEpics,
  projectsEpics,
  productsEpics,
);
