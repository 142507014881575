import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Typography } from '@mui/material';
import {
  ScaleButton,
  ScaleDivider,
  ScaleLink,
  ScaleSwitch,
} from '@telekom/scale-components-react';
import { enqueueSnackbar } from '../../redux/notifier/actions';


function getCookieValues(cookiesName) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${cookiesName}=`);
  if (parts.length === 2 && parts.pop().split(';').shift() === 'true') return true;
  return false;
}

const oneYear = new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toUTCString();
document.cookie = `ThirdPartyCookies=${getCookieValues('ThirdPartyCookies')}; expires=${oneYear}`;

const CookieSettings = () => {
  const dispatch = useDispatch();
  const [cookies, setCookies] = useState({
    ThirdPartyCookies: getCookieValues('ThirdPartyCookies'),
  });

  const handleSwitchChange = (event) => {
    setCookies({ ...cookies, [event.target.name]: event.target.checked });
  };

  const changeCookiesSettings = () => {
    try {
      document.cookie = `BasicCookies=true; expires=${oneYear}`;
      document.cookie = `ThirdPartyCookies=${cookies.ThirdPartyCookies}; expires=${oneYear}`;
      dispatch(enqueueSnackbar({
        message: 'CookiesSaved',
        options: { variant: 'success' },
      }));
    } catch (error) {
      dispatch(enqueueSnackbar({
        message: 'CookiesSaveError',
        options: { variant: 'error' },
      }));
    }
  };

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h6" gutterBottom display="inline">
          Required Cookies
        </Typography>
        <ScaleSwitch
          checked
          disabled
          label="Required"
        />
      </Grid>
      <Typography gutterBottom>
        These cookies are required to enable you
        to navigate through the websites and use key functions.
        <br />
        Required cookies make the basic functions of the website possible.
      </Typography>
      <ScaleDivider />
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h6" align="left" gutterBottom display="inline">
          Services by other companies (independent third-party providers)
        </Typography>
        <ScaleSwitch
          checked={cookies.ThirdPartyCookies}
          name="ThirdPartyCookies"
          onScale-change={handleSwitchChange}
        />
      </Grid>
      <Typography gutterBottom>
        Deutsche Telekom’s websites include links to third-party service providers,
        who provide their services under their own responsibility.
      </Typography>
      <Typography gutterBottom>
        When you visit Deutsche Telekom’s websites,
        cookies or similar technologies record data
        and could send it to third parties, in part for Deutsche Telekom’s own purposes.
        The scope, purpose and legal basis on which further processing is carried out
        for the third party’s own purposes can be found in the third party’s privacy notice.
        Information on the independent third party providers can be found in the&nbsp;
        <ScaleLink href="/data-privacy">Privacy Policy</ScaleLink>
        .
      </Typography>
      <ScaleButton
        variant="primary"
        onClick={() => changeCookiesSettings()}
      >
        Save
      </ScaleButton>
    </>
  );
};

export default CookieSettings;
