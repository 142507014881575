export const FETCH_PROJECTS_STARTED = 'FETCH_PROJECTS_STARTED';
export const FETCH_PROJECTS_FULLFILLED = 'FETCH_PROJECTS_FULLFILLED';
export const CREATE_PROJECT_STARTED = 'CREATE_PROJECT_STARTED';
export const CREATE_PROJECT_FULLFILLED = 'CREATE_PROJECT_FULLFILLED';
export const UPDATE_PROJECT_STARTED = 'UPDATE_PROJECT_STARTED';
export const UPDATE_PROJECT_FULLFILLED = 'UPDATE_PROJECT_FULLFILLED';
export const DELETE_PROJECT_STARTED = 'DELETE_PROJECT_STARTED';
export const DELETE_PROJECT_FULLFILLED = 'DELETE_PROJECT_FULLFILLED';
export const fetchProjects = (data) => ({
  type: FETCH_PROJECTS_STARTED, payload: data,
});
export const fetchProjectsFullfilled = (data) => (
  { type: FETCH_PROJECTS_FULLFILLED, payload: data }
);
export const createProject = (project) => (
  { type: CREATE_PROJECT_STARTED, payload: project }
);
export const createProjectFullfilled = (data) => (
  { type: CREATE_PROJECT_FULLFILLED, payload: data }
);
export const updateProject = (project) => (
  { type: UPDATE_PROJECT_STARTED, payload: project }
);
export const updateProjectFullfilled = (data) => (
  { type: UPDATE_PROJECT_FULLFILLED, payload: data }
);
export const deleteProject = (project) => (
  { type: DELETE_PROJECT_STARTED, payload: project }
);
export const deleteProjectFullfilled = (data) => (
  { type: DELETE_PROJECT_FULLFILLED, payload: data }
);
