import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  createUser as createSafescarfUser,
  updateUser as updateSafescarfUser,
} from '../../../../redux/safescarf/users/actions';

import {
  fetchUsers as fetchPortalUsers,
} from '../../../../redux/admin/users/actions';

import rbacCheck from '../../../../rbac/rbacCheck';
import Table from '../../../../components/Table';

function mergeUsers(portalUsers, safescarfUsers) {
  const mergedUsers = portalUsers.map((pUser) => {
    const safescarfUser = safescarfUsers.find((ssUser) => ssUser.username === pUser.email);
    return {
      username: pUser.email,
      safescarfId: safescarfUser?.id,
      is_active: Boolean(safescarfUser?.is_active),
      isSafescarfUser: Boolean(safescarfUser),
    };
  });
  return mergedUsers;
}


const Users = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user);
  const portalUsers = useSelector((state) => state.admin.users);
  const safescarfUsers = useSelector((state) => state.safescarf.users);

  useEffect(() => {
    dispatch(fetchPortalUsers());
  }, []);

  const mergedUsers = mergeUsers(portalUsers, safescarfUsers);
  const mapUsersToRows = mergedUsers.map((user) => ({
    key: user.username,
    data: [
      {
        field: 'username',
        value: user.username,
      },
      {
        field: 'is_active',
        value: user.is_active ? 'Activated' : 'Deactivated',
      },
      {
        field: 'actions',
        value: 'switch',
        props: {
          disabled: !rbacCheck(userData.roles, '/products/safescarf/users:PUT', { userEmail: userData.email, rowEmail: user.username }),
          checked: user.is_active,
          label: user.is_active ? 'Deactivate' : 'Activate',
          'onScale-change': () => {
            if (user.isSafescarfUser) {
              dispatch(updateSafescarfUser({
                id: user.safescarfId,
                username: user.username,
                is_active: !user.is_active,
              }));
            } else {
              dispatch(createSafescarfUser({
                username: user.username,
                email: user.username,
                is_active: true,
              }));
            }
          },
        },
      },
    ],
  }));

  return (
    <Table
      header="Users"
      rows={mapUsersToRows}
      fields={[
        {
          id: 'username',
          title: 'Username',
        },
        {
          id: 'is_active',
          title: 'Activation Status',
        },
        {
          id: 'actions',
          title: 'Actions',
        },
      ]}
    />
  );
};


export default Users;
