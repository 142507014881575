import { ofType, combineEpics } from 'redux-observable';
import { mergeMap, catchError } from 'rxjs/operators';
import { from, of } from 'rxjs';
import Auth from '../../../api/auth';
import {
  FETCH_BUSINESS_UNITS_STARTED,
  fetchBusinessUnitsFullfilled,
  CREATE_BUSINESS_UNIT_STARTED,
  createBusinessUnitFullfilled,
  UPDATE_BUSINESS_UNIT_STARTED,
  updateBusinessUnitFullfilled,
  DELETE_BUSINESS_UNIT_STARTED,
  deleteBusinessUnitFullfilled,
} from './actions';
import { enqueueSnackbar } from '../../notifier/actions';
import { requestFailed } from '../../status/actions';

const fetchBusinessUnitsEpic = (action$) => action$.pipe(
  ofType(FETCH_BUSINESS_UNITS_STARTED),
  mergeMap((action) => from(Auth.fetchV1BusinessUnits(action.payload)).pipe(
    mergeMap((response) => of(
      fetchBusinessUnitsFullfilled(response.data),
    )),
    catchError((error) => of(
      requestFailed(),
      enqueueSnackbar({
        message: error.message,
        options: { variant: 'error' },
      }),
    )),
  )),
);

const createBusinessUnitEpic = (action$) => action$.pipe(
  ofType(CREATE_BUSINESS_UNIT_STARTED),
  mergeMap((action) => from(Auth.createV1BusinessUnit(action.payload)).pipe(
    mergeMap((response) => of(
      createBusinessUnitFullfilled(response.data),
      enqueueSnackbar({
        message: 'businessUnitCreation',
        values: { name: response.data.name },
        options: { variant: 'success' },
      }),
    )),
    catchError((error) => of(
      requestFailed(),
      enqueueSnackbar({
        message: error.message,
        options: { variant: 'error' },
      }),
    )),
  )),
);

const updateBusinessUnitEpic = (action$) => action$.pipe(
  ofType(UPDATE_BUSINESS_UNIT_STARTED),
  mergeMap((action) => from(Auth.updateV1BusinessUnit(action.payload)).pipe(
    mergeMap((response) => of(
      updateBusinessUnitFullfilled(response.data),
      enqueueSnackbar({
        message: 'businessUnitUpdated',
        options: { variant: 'success' },
      }),
    )),
    catchError((error) => of(
      requestFailed(),
      enqueueSnackbar({
        message: error.message,
        options: { variant: 'error' },
      }),
    )),
  )),
);

const deleteBusinessUnitEpic = (action$) => action$.pipe(
  ofType(DELETE_BUSINESS_UNIT_STARTED),
  mergeMap((action) => from(Auth.deleteV1BusinessUnit(action.payload)).pipe(
    mergeMap((response) => of(
      deleteBusinessUnitFullfilled(response.data),
      enqueueSnackbar({
        message: 'businessUnitDeleted',
        values: { name: response.data.name },
        options: { variant: 'success' },
      }),
    )),
    catchError((error) => of(
      requestFailed(),
      enqueueSnackbar({
        message: error.message,
        options: { variant: 'error' },
      }),
    )),
  )),
);


export default combineEpics(
  fetchBusinessUnitsEpic,
  createBusinessUnitEpic,
  updateBusinessUnitEpic,
  deleteBusinessUnitEpic,
);
